import React, { useEffect, useState } from 'react';
import { Box, Flex, HStack, Icon } from '@chakra-ui/react';
import { IoLocationOutline } from 'react-icons/all';
import PropTypes from 'prop-types';
import cookies from 'react-cookies';
import SelectionModal from '../SelectionModal/SelectionModal';

function LocationWithRatingsControl(props) {
  const { onSelect, locationRatings } = props;
  const [locations, setLocations] = useState([]);
  const [selectedLocation, setSelectedLocation] = useState(null);
  const [isModalOpen, setIsModalOpen] = useState(false);
  useEffect(() => {
    if (cookies.load('locationData')) {
      const locations_ = [];
      const getLocationRating = (locationName) => {
        let ratingsData = '';
        locationRatings.every(locationItem => {
          if (locationItem.LocationName.trim() === locationName) {
            ratingsData = locationItem;
            return false;
          }
          return true;
        });

        if (!ratingsData) {
          return null;
        }

        return (
          <HStack spacing={2} fontWeight={'normal'} fontSize={12} mt={2}>
            <Box>
              Rating <Box fontWeight={'bold'} as={'span'}>★{ratingsData.Rating}</Box>
            </Box>
            <Box>
              Orders <Box fontWeight={'bold'} as={'span'}>{ratingsData.Orders}</Box>
            </Box>
            <Box>
              Feedback&nbsp;
              <Box fontWeight={'bold'} as={'span'}>
                {ratingsData.TotalFeedback} {ratingsData.FeedbackPercent && (`(${ratingsData.FeedbackPercent}%)`)}
              </Box>
            </Box>
          </HStack>
        );
      };
      cookies.load('locationData').map(locationData => {
        locations_.push({
          value: locationData.LocationID,
          label: locationData.LocationName.trim(),
          label2: getLocationRating(locationData.LocationName.trim()),
        });
        return locationData;
      });
      setLocations(locations_);

      const lastSelectedLocation = cookies.load('lastSelectedLocation') || locations_[0];
      setSelectedLocation(lastSelectedLocation);
    }
  }, [locationRatings]);

  return (
    <>
      <Flex alignItems={'center'} cursor={'pointer'} color={'white'} bg={'#410E90'} borderRadius={4} p={2} fontSize={14}
            whiteSpace={'nowrap'} h={10}
            onClick={() => {
              setIsModalOpen(true);
            }}>
        <Icon as={IoLocationOutline} color={'white'} mr={1} /> {selectedLocation && selectedLocation.label}
      </Flex>
      <SelectionModal options={locations} headerBg={'#410E90'} bg={'#F4F4F4'} title={'Select Location'}
                      searchPlaceholder={'Search Location'} selectionColor={'#410E90'}
                      selectedOption={selectedLocation && selectedLocation.value}
                      onOptionSelect={(option) => {
                        onSelect(option.value);
                        setIsModalOpen(false);
                        setSelectedLocation(option);
                        cookies.save('lastSelectedLocation', { value: option.value, label: option.label });
                      }}
                      isOpen={isModalOpen}
                      isSearchable
                      onModalClose={() => {
                        setIsModalOpen(false);
                      }} />
    </>
  );
}

LocationWithRatingsControl.propTypes = {
  onSelect: PropTypes.func,
  locationRatings: PropTypes.array,
};

export default LocationWithRatingsControl;
