import React from 'react';
import PropTypes from 'prop-types';
import { Flex, Spinner } from '@chakra-ui/react';

function Loader(props) {
  if (!props.isVisible) {
    return null;
  }
  return (
    <Flex alignItems={'center'} justify={'center'} backgroundColor={'white'} opacity={0.5} pos={'fixed'} left={0}
          top={0} height={'100vh'} width={'100vw'} zIndex={1000}>
      <Spinner thickness='4px'
               speed='0.65s'
               emptyColor='gray.200'
               color='blue.500'
               size='xl' />
    </Flex>
  );
}

Loader.propTypes = {
  isVisible: PropTypes.bool,
};

export default Loader;
