import React from 'react';
import PropTypes from 'prop-types';
import { Box } from '@chakra-ui/react';

function Overlay(props) {
  if (props.isSidebarCollapsed) {
    return null;
  }
  return (
    <Box pos={'fixed'} backgroundColor={'rgb(0 0 0 / 50%)'} h={'100%'} w={'100%'} zIndex={3}
         display={{ base: 'block', md: 'none' }} onClick={props.onClick} top={0} left={0} />
  );
}

Overlay.propTypes = {
  isSidebarCollapsed: PropTypes.bool,
  onClick: PropTypes.func,
};

export default Overlay;
