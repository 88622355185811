import React, { useEffect, useState } from 'react';
import {
  Box,
  Button,
  Center,
  Checkbox,
  Divider,
  Image,
  Link,
  Stack,
  Textarea,
  Wrap,
  WrapItem,
} from '@chakra-ui/react';
import { useHistory } from 'react-router-dom';
import { useQuery } from '../../hooks/useQuery';
import { apiCall } from '../../api/base';
import moment from 'moment';
import Scale from './Scale';
import swal from 'sweetalert';
import cookies from 'react-cookies';

function Feedback() {
  const [feedbackData, setFeedbackData] = useState({});
  const [ratings, setRatings] = useState({ Scale1: 0, Scale2: 0, Scale3: 0, Scale4: 0, Scale5: 0 });
  const [scaleTags, setScaleTags] = useState({ Scale1: [], Scale2: [], Scale3: [], Scale4: [], Scale5: [] });
  const [checkedTags, setCheckedTags] = useState({ Scale1: [], Scale2: [], Scale3: [], Scale4: [], Scale5: [] });
  const [questions, setQuestions] = useState({ Question1: '', Question2: '', Question3: '' });

  const [scaleSize, setScaleSize] = useState(0);
  const [scaleType, setScaleType] = useState(null);
  const [lastRatedScale, setLastRatedScale] = useState('');

  const queryParams = Object.fromEntries(useQuery());

  let history = useHistory();

  const [error, setError] = useState(null);

  const notFoundError = <>
    <Box>SORRY</Box>
    <Box mt={4}>We couldn't open the requested feedback form for you.</Box>
    <Box mt={4}>Your feedback is important to us.</Box>
    <Box>Please email us at <Link to={'mailto:support@rancelab.com'} textDecoration={'underline'}
                                  target={'blank'} color={'blue'}>support@rancelab.com</Link></Box>
    <Box>Thank you.</Box>
  </>;

  useEffect(() => {

    if (!history.location.state) {
      history.replace(history.location.pathname, queryParams);
    }

    const query = history.location.state;

    if (!query['rcid']) {
      setError(notFoundError);
      return;
    }

    (async function() {
      const response = await apiCall({
        url: `${process.env.REACT_APP_API_HOST}/api/fr8-feedback-master?rcId=${query['rcid']}&feedback-master-id=${query['feedback-master-id']}`,
        method: 'get',
        headers: {
          Authorization: 'Bearer eyJhbGciOiJIUzI1NiJ9.UmFuY2VMYWI.sGv0nwn3i6eINez1o5f43eLAJO4uDhVpKZja3R8Ou5k',
        },
        skipErrorHandling: true,
      });
      if (!response) {
        setError(notFoundError);
        return;
      }
      const lastSubmittedFeedbackId = cookies.load('lastSubmittedFeedbackId') + '';
      if (`${query['feedback-master-id']}${query['sale-serial-number']}${query['voucher-number']}` === lastSubmittedFeedbackId) {
        swal({ text: 'You have already submitted the feedback!' }).then(() => {
          window.location.href = response.WebRedirectURL;
        });
        return;
      }
      setFeedbackData(response);
      const scaleConfig = response.ScaleType.split(' ');
      setScaleSize(parseInt(scaleConfig[1]));
      setScaleType(scaleConfig[2]);
    })();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [history]);

  const getScaleTags = (scaleString) => {
    return scaleString.split(',').map(tag => {
      return tag.trim();
    });
  };

  const handleRatingSelect = ({ rating, scale }) => {
    setRatings({
      ...ratings,
      [scale]: rating,
    });
    setLastRatedScale(scale);
    if ((scaleSize === 5 && rating <= 3) || (scaleSize === 3 && rating <= 2)) {
      setScaleTags({
        ...scaleTags,
        [`${scale}`]: getScaleTags(feedbackData[`${scale}Tags`]),
      });
    } else {
      setScaleTags({
        ...scaleTags,
        [`${scale}`]: [],
      });
    }
  };

  const handleSubmit = async () => {
    await apiCall({
      url: `${process.env.REACT_APP_API_HOST}/api/online-feedback-data`,
      method: 'post',
      data: {
        'rcId': history.location.state['rcid'],
        'FeedbackID': history.location.state['feedback-master-id'],
        'Scale1': ratings.Scale1,
        'Scale1Tags': checkedTags.Scale1.join(','),
        'Scale2': ratings.Scale2,
        'Scale2Tags': checkedTags.Scale2.join(','),
        'Scale3': ratings.Scale3,
        'Scale3Tags': checkedTags.Scale3.join(','),
        'Scale4': ratings.Scale4,
        'Scale4Tags': checkedTags.Scale4.join(','),
        'Scale5': ratings.Scale5,
        'Scale5Tags': checkedTags.Scale5.join(','),
        'Question1': questions.Question1,
        'Question2': questions.Question2,
        'Question3': questions.Question3,
        'QuestionTags': feedbackData.QuestionTags,
        'FeedbackDateTime': history.location.state['voucher-datetime'],
        'SaleSerialNumber': history.location.state['sale-serial-number'],
      },
      headers: {
        Authorization: 'Bearer 9F80F833-2F0A-418F-A8A5-6CE41CF4136A-63B70D12-B080-492B-B750-739139462FAD',
      },
    });

    swal({ title: 'Feedback', text: 'Thank you for submitting the feedback' })
      .then(() => {
        cookies.save('lastSubmittedFeedbackId', `${history.location.state['feedback-master-id']}${history.location.state['sale-serial-number']}${history.location.state['voucher-number']}`);
        window.location.href = feedbackData.WebRedirectURL;
      });

  };

  const handleTagsChange = ({ scale, tag, isChecked }) => {
    if (isChecked) {
      const tags = checkedTags[scale];
      tags.push(tag);
      setCheckedTags({
        ...checkedTags,
        [scale]: tags,
      });
    } else {
      const tags = checkedTags[scale];
      const index = tags.indexOf(tag);
      if (index !== -1) {
        tags.splice(index, 1);
      }
      setCheckedTags({
        ...checkedTags,
        [scale]: tags,
      });
    }
  };
  if (error) {
    return (
      <Center p={4} height={'100vh'} textAlign={'center'}>
        <Box>{error}</Box>
      </Center>

    );
  }
  if (!feedbackData.FeedbackName) {
    return null;
  }
  return (
    <Box p={4} bgColor={feedbackData.WebBackgroundColor} minH={'100vh'} bgImage={feedbackData.WebBackgroundImage}
         color={feedbackData.WebFontColor}>
      <Center>
        <Image w={20} src={feedbackData.WebLogo} />
      </Center>
      <Box textAlign={'center'} mt={2} fontSize={14}>{feedbackData.FeedbackName}</Box>
      <Box textTransform={'uppercase'} fontWeight={'medium'} fontSize={20} textAlign={'center'}
           mt={2}>{feedbackData.FeedbackDescription}</Box>
      <Divider mt={4} />
      <Wrap mt={4} fontSize={12} justify={'space-between'}>
        <WrapItem>
          Order #: {history.location.state['voucher-number']}
        </WrapItem>
        <WrapItem>
          {moment(history.location.state['voucher-datetime']).format('Do MMMM YYYY, hh:mm A')}
        </WrapItem>
      </Wrap>
      <Divider mt={4} />
      <Stack mt={4} spacing={4}>
        {feedbackData.Scale1 && (
          <Scale label={feedbackData.Scale1} selectedRating={ratings.Scale1} size={scaleSize} type={scaleType}
                 onRatingSelected={(rating) => handleRatingSelect({ rating, scale: 'Scale1' })} />
        )}
        {feedbackData.Scale2 && (
          <Scale label={feedbackData.Scale2} selectedRating={ratings.Scale2} size={scaleSize} type={scaleType}
                 onRatingSelected={(rating) => handleRatingSelect({ rating, scale: 'Scale2' })} />
        )}
        {feedbackData.Scale3 && (
          <Scale label={feedbackData.Scale3} selectedRating={ratings.Scale3} size={scaleSize} type={scaleType}
                 onRatingSelected={(rating) => handleRatingSelect({ rating, scale: 'Scale3' })} />
        )}
        {feedbackData.Scale4 && (
          <Scale label={feedbackData.Scale4} selectedRating={ratings.Scale4} size={scaleSize} type={scaleType}
                 onRatingSelected={(rating) => handleRatingSelect({ rating, scale: 'Scale4' })} />
        )}
        {feedbackData.Scale5 && (
          <Scale label={feedbackData.Scale5} selectedRating={ratings.Scale5} size={scaleSize} type={scaleType}
                 onRatingSelected={(rating) => handleRatingSelect({ rating, scale: 'Scale5' })} />
        )}
      </Stack>
      <Box mt={4}>
        {scaleTags[lastRatedScale] && scaleTags[lastRatedScale].length > 0 && (
          <>
            <Divider mt={4} />
            <Center mt={4}>{feedbackData.QuestionTags}</Center>
          </>
        )}
        <Stack mt={2}>
          {lastRatedScale === 'Scale1' && scaleTags.Scale1.map(tag => {
            return (
              <Checkbox borderColor={feedbackData.WebFontColor} key={tag}
                        isChecked={checkedTags.Scale1.indexOf(tag) > -1}
                        onChange={(e) => handleTagsChange({ tag, scale: 'Scale1', isChecked: e.target.checked })}
              >
                {tag}
              </Checkbox>
            );
          })}
          {lastRatedScale === 'Scale2' && scaleTags.Scale2.map(tag => {
            return (
              <Checkbox borderColor={feedbackData.WebFontColor} key={tag}
                        isChecked={checkedTags.Scale2.indexOf(tag) > -1}
                        onChange={(e) => handleTagsChange({ tag, scale: 'Scale2', isChecked: e.target.checked })}
              >
                {tag}
              </Checkbox>
            );
          })}
          {lastRatedScale === 'Scale3' && scaleTags.Scale3.map(tag => {
            return (
              <Checkbox borderColor={feedbackData.WebFontColor} key={tag}
                        isChecked={checkedTags.Scale3.indexOf(tag) > -1}
                        onChange={(e) => handleTagsChange({ tag, scale: 'Scale3', isChecked: e.target.checked })}
              >
                {tag}
              </Checkbox>
            );
          })}
          {lastRatedScale === 'Scale4' && scaleTags.Scale4.map(tag => {
            return (
              <Checkbox borderColor={feedbackData.WebFontColor} key={tag}
                        isChecked={checkedTags.Scale4.indexOf(tag) > -1}
                        onChange={(e) => handleTagsChange({ tag, scale: 'Scale4', isChecked: e.target.checked })}
              >
                {tag}
              </Checkbox>
            );
          })}
          {lastRatedScale === 'Scale5' && scaleTags.Scale5.map(tag => {
            return (
              <Checkbox borderColor={feedbackData.WebFontColor} key={tag}
                        isChecked={checkedTags.Scale5.indexOf(tag) > -1}
                        onChange={(e) => handleTagsChange({ tag, scale: 'Scale5', isChecked: e.target.checked })}
              >
                {tag}
              </Checkbox>
            );
          })}
        </Stack>
      </Box>
      <Divider mt={4} />
      <Stack mt={4} spacing={4}>
        {feedbackData.Question1 && (
          <Textarea _placeholder={{
            color: feedbackData.WebFontColor,
          }} variant={'unstyled'} borderBottom={'1px solid'} borderColor={'gray.200'}
                    placeholder={feedbackData.Question1} value={questions.Question1}
                    onChange={(e) => setQuestions({
                      ...questions,
                      Question1: e.target.value,
                    })} />
        )}
        {feedbackData.Question2 && (
          <Textarea _placeholder={{
            color: feedbackData.WebFontColor,
          }} variant={'unstyled'} borderBottom={'1px solid'} borderColor={'gray.200'}
                    placeholder={feedbackData.Question2} value={questions.Question2}
                    onChange={(e) => setQuestions({
                      ...questions,
                      Question2: e.target.value,
                    })} />
        )}
        {feedbackData.Question3 && (
          <Textarea _placeholder={{
            color: feedbackData.WebFontColor,
          }} variant={'unstyled'} borderBottom={'1px solid'} borderColor={'gray.200'}
                    placeholder={feedbackData.Question3} value={questions.Question3}
                    onChange={(e) => setQuestions({
                      ...questions,
                      Question3: e.target.value,
                    })} />
        )}
      </Stack>
      <Center mt={4}>
        <Button colorScheme={'blue'} onClick={handleSubmit}
                disabled={!ratings.Scale1 && !ratings.Scale2 && !ratings.Scale3 && !ratings.Scale4 && !ratings.Scale5}>Submit</Button>
      </Center>
    </Box>
  );
}

Feedback.propTypes = {};

export default Feedback;
