import React from 'react';
import { Box, Flex } from '@chakra-ui/react';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';

function ReviewItem(props) {
  const { review, qs, index } = props;
  return (
    <Box d={'block'} as={Link} p={5} borderBottom={'1px solid'} borderColor={'gray.200'}
         to={`/admin/feedback-analysis/review?location=${qs.location}&feedback=${qs.feedback}&duration=${qs.duration}&type=${qs.type}&index=${index}`}>
      <Flex justifyContent={'space-between'} fontSize={12}>
        <Box>{review.FeedbackDateTime}</Box>
        <Box>User: {review.User}</Box>
      </Flex>
      <Flex justifyContent={'space-between'} mt={4}>
        <Box fontWeight={'bold'}>
          <Box>{review.CustomerName}</Box>
          <Box mt={2}>★ {review.AverageScale}</Box>
        </Box>
        <Box>
          <Box>{review.VchNumber} | <Box as={'span'} fontWeight={'bold'}>₹{review.BillAmount}</Box></Box>
          <Box mt={2}>Table {review.TableName} Pax {review.Pax}</Box>
        </Box>
      </Flex>
      <Box mt={4} fontSize={14}>
        <Box>{review.Question1}</Box>
        <Box>{review.Question2}</Box>
        <Box>{review.Question3}</Box>
        <Box fontWeight={'bold'}>read more...</Box>
      </Box>
    </Box>
  );
}

ReviewItem.propTypes = {
  review: PropTypes.object,
  qs: PropTypes.object,
  index: PropTypes.number,
};

export default ReviewItem;
