import React, { useEffect, useState } from 'react';
import { Flex, Icon } from '@chakra-ui/react';
import { MdDateRange } from 'react-icons/all';
import PropTypes from 'prop-types';
import cookies from 'react-cookies';
import SelectionModal from '../SelectionModal/SelectionModal';
import DateRangeModal from '../DateRangeModal/DateRangeModal';
import moment from 'moment';

function IntervalControl(props) {
  const { onSelect, showDateRange } = props;
  const [intervals, setIntervals] = useState([]);
  const [selectedInterval, setSelectedInterval] = useState(null);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isDateRangeModalOpen, setIsDateRangeModalOpen] = useState(false);
  useEffect(() => {
    let intervals_ = [
      { value: 0, label: 'today' },
      { value: 1, label: 'yesterday' },
      { value: 2, label: 'this month' },
      { value: 3, label: 'last month' },
      { value: 9, label: 'this week' },

    ];
    if (showDateRange) {
      intervals_.push({ value: 8, label: 'date range' });
    }
    intervals_ = intervals_.filter(interval => {
      return props.valuesToHide.indexOf(interval.value) <= -1;
    });
    setIntervals(intervals_);

    let lastSelectedInterval;
    if (cookies.load('lastSelectedInterval') && cookies.load('lastSelectedInterval').value === 8 && !showDateRange) {
      lastSelectedInterval = intervals_[0];
    } else {
      lastSelectedInterval = cookies.load('lastSelectedInterval') || intervals_[0];
    }

    if (props.valuesToHide.indexOf(lastSelectedInterval.value) > -1) {
      lastSelectedInterval = intervals_[0];
    }

    setSelectedInterval(lastSelectedInterval);
    if (cookies.load('lastSelectedInterval') && lastSelectedInterval.value !== cookies.load('lastSelectedInterval').value) {
      onSelect(lastSelectedInterval.value);
      cookies.save('lastSelectedInterval', lastSelectedInterval);
    }


  }, [onSelect, props.valuesToHide, showDateRange]);

  const getFromDate = () => {
    if (cookies.load('lastSelectedInterval') && cookies.load('lastSelectedInterval').value === 8) {
      return moment(cookies.load('lastSelectedInterval').range.split('|')[0].split(' ')[0], 'DD/MM/YYYY').format('YYYY-MM-DD');
    }

    return '';
  };
  const getToDate = () => {
    if (cookies.load('lastSelectedInterval') && cookies.load('lastSelectedInterval').value === 8) {
      return moment(cookies.load('lastSelectedInterval').range.split('|')[1].split(' ')[0], 'DD/MM/YYYY').format('YYYY-MM-DD');
    }

    return '';
  };

  const getDisplayText = () => {
    if (!selectedInterval) {
      return '';
    }
    if (selectedInterval.range) {
      return `
      ${cookies.load('lastSelectedInterval').range.split('|')[0].split(' ')[0]} - ${cookies.load('lastSelectedInterval').range.split('|')[1].split(' ')[0]}
      `;
    }
    return selectedInterval.label;
  };

  return (
    <>
      <Flex alignItems={'center'} cursor={'pointer'} color={'white'} bg={'#8E44AD'} borderRadius={4} p={2} fontSize={14}
            whiteSpace={'nowrap'} h={10}
            onClick={() => {
              setIsModalOpen(true);
            }}>
        <Icon as={MdDateRange} color={'white'} mr={1} /> {getDisplayText()}
      </Flex>
      <SelectionModal options={intervals} bg={'#F4F4F4'} title={'Select Duration'}
                      headerBg={'#8E44AD'}
                      isSearchable={false}
                      searchPlaceholder={'Search Duration'} selectionColor={'#8E44AD'}
                      selectedOption={selectedInterval && selectedInterval.value}
                      onOptionSelect={(option) => {
                        if (option.value !== 8) {
                          onSelect(option.value);
                          setIsModalOpen(false);
                          setSelectedInterval(option);
                          cookies.save('lastSelectedInterval', option);
                        } else {
                          setIsModalOpen(false);
                          setIsDateRangeModalOpen(true);
                        }
                      }}
                      isOpen={isModalOpen}
                      onModalClose={() => {
                        setIsModalOpen(false);
                      }} />

      <DateRangeModal
        bg={'#F4F4F4'}
        headerBg={'#8E44AD'}
        isOpen={isDateRangeModalOpen}
        from={getFromDate()}
        to={getToDate()}
        onModalClose={() => {
          if (cookies.load('lastSelectedInterval') && cookies.load('lastSelectedInterval').value !== 8) {
            const option = { value: 0, label: 'today' };
            onSelect(option.value);
            setSelectedInterval(option);
            cookies.save('lastSelectedInterval', option);
          }
          setIsDateRangeModalOpen(false);
        }}
        onRangeSelect={range => {
          onSelect(range);
          setIsDateRangeModalOpen(false);
          const option = { value: 8, label: 'date range', range };
          setSelectedInterval(option);
          cookies.save('lastSelectedInterval', option);
        }} />
    </>
  );
}

IntervalControl.propTypes = {
  onSelect: PropTypes.func,
  valuesToHide: PropTypes.array,
  showDateRange: PropTypes.bool,
};

IntervalControl.defaultProps = {
  showDateRange: false,
};

export default IntervalControl;
