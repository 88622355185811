import React, { useEffect, useState } from 'react';
import { Box, Divider, Flex, HStack, Icon } from '@chakra-ui/react';
import Loader from '../../components/Loader/Loader';
import Controls from '../../components/Controls/Controls';
import AlphaWidget from '../FeedbackAnalysis/AlphaWidget';
import cookies from 'react-cookies';
import useGetTableData from '../../hooks/useGetTableData';
import { FiChevronLeft } from 'react-icons/all';
import { Link } from 'react-router-dom';
import RatingSummary from './RatingSummary';
import queryString from 'querystring';

function FeedbackAnalysisRatings(props) {
  const REPORT_ID = 70;

  const [location, setLocation] = useState(cookies.load('lastSelectedLocation') ? cookies.load('lastSelectedLocation').value : 0);
  const [locationRatings, setLocationRatings] = useState([]);

  const [scaleData, setScaleData] = useState(null);

  const qs = queryString.parse(window.location.search.substring(1));

  const { tableData, isLoading } = useGetTableData({
    reportId: REPORT_ID,
    duration: 8,
    location,
    feedback: qs.feedback,
    dateRange: qs.duration,
    shouldGetRawResponse: true,
  });


  useEffect(() => {
    if (tableData.ExecuteMobiDataResult) {
      setLocationRatings(JSON.parse(tableData.ExecuteMobiDataResult[0]));
      const selectedLocation = cookies.load('lastSelectedLocation') ? cookies.load('lastSelectedLocation').label : 'All Location';
      const scaleData_ = JSON.parse(tableData.ExecuteMobiDataResult[0]).filter(locationData => {
        return locationData.LocationName === selectedLocation;
      })[0];
      setScaleData(scaleData_);
    }
  }, [tableData]);

  return (
    <>
      <Box pos={'fixed'} top={5} left={10} fontWeight={'bold'} zIndex={2}>Ratings</Box>
      <Flex as={Link} bg={'gray.200'} px={2} py={1} borderRadius={4} alignItems={'center'} pos={'fixed'} top={4}
            right={4}
            fontWeight={'bold'} zIndex={2} to={'/admin/feedback-analysis'}>
        Back <Icon boxSize={5} as={FiChevronLeft} />
      </Flex>
      <Box p={'20px'} pos={'relative'}>
        <Loader isVisible={isLoading} />

        <Controls
          onLocationWithRatingsSelect={setLocation}
          locationRatings={locationRatings}
        />

        {scaleData && <HStack spacing={6} pos={'absolute'} right={5} top={4} alignItems={'flex-start'}>
          <AlphaWidget title={'Rating'} subtitle={`★ ${scaleData.Rating}`} />
          <AlphaWidget title={'Order'} subtitle={scaleData.Orders} />
          <AlphaWidget title={'Feedback'}
                       subtitle={`${scaleData.TotalFeedback} ${scaleData.FeedbackPercent ? (`(${scaleData.FeedbackPercent}%)`) : ''}`}
          />
        </HStack>}

      </Box>
      <Divider />

      {scaleData && [1, 2, 3, 4, 5].map(scale => {
        if (!scaleData[`Scale${scale}Name`]) {
          return null;
        }
        return (
          <div key={scale}>
            <Box p={5}>
              <RatingSummary title={scaleData[`Scale${scale}Name`]}
                             percentageMap={{
                               '5': parseInt(scaleData[`Scale${scale}Count5Percent`]),
                               '4': parseInt(scaleData[`Scale${scale}Count4Percent`]),
                               '3': parseInt(scaleData[`Scale${scale}Count4Percent`]),
                               '2': parseInt(scaleData[`Scale${scale}Count2Percent`]),
                               '1': parseInt(scaleData[`Scale${scale}Count1Percent`]),
                             }}
                             countMap={{
                               '5': scaleData[`Scale${scale}Count5`],
                               '4': scaleData[`Scale${scale}Count4`],
                               '3': scaleData[`Scale${scale}Count4`],
                               '2': scaleData[`Scale${scale}Count2`],
                               '1': scaleData[`Scale${scale}Count1`],
                             }}
              />
            </Box>
            <Divider />
          </div>
        );
      })}
    </>
  );
}

FeedbackAnalysisRatings.propTypes = {};

export default FeedbackAnalysisRatings;
