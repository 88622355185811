import React, { useState } from 'react';
import { BrowserRouter as Router, Redirect, Route, Switch, useHistory } from 'react-router-dom';
import Sidebar from '../Sidebar/Sidebar';
import Overlay from '../Overlay/Overlay';
import Topbar from '../Topbar/Topbar';
import { Box } from '@chakra-ui/react';
import { useQuery } from '../../hooks/useQuery';
import SaleByItem from '../../containers/SaleByItem/SaleByItem';
import cookies from 'react-cookies';
import SaleByCategory from '../../containers/SaleByCategory/SaleByCategory';
import SaleRegister from '../../containers/SaleRegister/SaleRegister';
import SaleByVoucher from '../../containers/SaleByVoucher/SaleByVoucher';
import SaleByHour from '../../containers/SaleByHour/SaleByHour';
import SaleByWeekday from '../../containers/SaleByWeekday/SaleByWeekday';
import CustomerMixAnalysis from '../../containers/CustomerMixAnalysis/CustomerMixAnalysis';
import SaleByLocation from '../../containers/SaleByLocation/SaleByLocation';
import Settings from '../../containers/Settings/Settings';
import SaleKpi from '../../containers/SaleKpi/SaleKpi';
import DayEndReport from '../../containers/DayEndReport/DayEndReport';
import LengthOfSeating from '../../containers/LengthOfSeating/LengthOfSeating';
import TransactionAnalysis from '../../containers/TransactionAnalysis/TransactionAnalysis';
import SaleByShift from '../../containers/SaleByShift/SaleByShift';
import StockStatement from '../../containers/StockStatement/StockStatement';
import SupplierPerformanceStatement from '../../containers/SupplierPerformanceStatement/SupplierPerformanceStatement';
import BankAndCashbook from '../../containers/BankAndCashbook/BankAndCashbook';
import FeedbackAnalysis from '../../containers/FeedbackAnalysis/FeedbackAnalysis';
import FeedbackAnalysisRatings from '../../containers/FeedbackAnalysisRatings/FeedbackAnalysisRatings';
import FeedbackAnalysisReviews from '../../containers/FeedbackAnalysisReviews/FeedbackAnalysisReviews';
import FeedbackAnalysisReview from '../../containers/FeedbackAnalysisReview/FeedbackAnalysisReview';

function Admin() {
  const query = useQuery();
  const history = useHistory();

  const [timestamp, setTimestamp] = useState(new Date().getTime());

  const isLoggedIn = cookies.load('userName');
  if (!isLoggedIn) {
    history.push('/login');
  }

  const [isSidebarCollapsed, setIsSidebarCollapsed] = useState(query.get('isSidebarCollapsed') === null ? true : !!parseInt(query.get('isSidebarCollapsed')));
  const toggleSidebar = () => {
    const newState = !isSidebarCollapsed;

    setIsSidebarCollapsed(newState);

    const params = new URLSearchParams();
    params.set('isSidebarCollapsed', newState ? '1' : '0');
    history.push({ search: params.toString() });
  };

  const handleMenuClicked = () => {
    setIsSidebarCollapsed(true);
    setTimestamp(new Date().getTime());
  };

  return (
    <Box pl={{ base: 0, md: '280px' }} pt={'64px'}>
      <Router>
        <Sidebar isSidebarCollapsed={isSidebarCollapsed} menuClicked={handleMenuClicked} />
        <Overlay isSidebarCollapsed={isSidebarCollapsed} onClick={toggleSidebar} />
        <Topbar handleMenuClick={toggleSidebar} timestamp={timestamp} />
        <Box>
          <Switch>
            <Route path='/admin/sale-by-item'>
              <SaleByItem />
            </Route>
            <Route path='/admin/sale-by-category'>
              <SaleByCategory />
            </Route>
            <Route path='/admin/sale-register'>
              <SaleRegister />
            </Route>
            <Route path='/admin/sale-by-voucher'>
              <SaleByVoucher />
            </Route>
            <Route path='/admin/sale-by-hour'>
              <SaleByHour />
            </Route>
            <Route path='/admin/sale-by-weekday'>
              <SaleByWeekday />
            </Route>
            <Route path='/admin/customer-mix-analysis'>
              <CustomerMixAnalysis />
            </Route>
            <Route path='/admin/sale-by-location'>
              <SaleByLocation />
            </Route>
            <Route path='/admin/settings'>
              <Settings />
            </Route>
            <Route path='/admin/sale-kpi'>
              <SaleKpi />
            </Route>
            <Route path='/admin/day-end-report'>
              <DayEndReport />
            </Route>
            <Route path='/admin/length-of-seating'>
              <LengthOfSeating />
            </Route>
            <Route path='/admin/transaction-analysis'>
              <TransactionAnalysis />
            </Route>
            <Route path='/admin/sale-by-shift'>
              <SaleByShift />
            </Route>
            <Route path='/admin/stock-statement'>
              <StockStatement />
            </Route>
            <Route path='/admin/supplier-performance-statement'>
              <SupplierPerformanceStatement />
            </Route>
            <Route path='/admin/bank-and-cashbook'>
              <BankAndCashbook />
            </Route>
            <Route path='/admin/feedback-analysis/ratings'>
              <FeedbackAnalysisRatings />
            </Route>
            <Route path='/admin/feedback-analysis/reviews'>
              <FeedbackAnalysisReviews />
            </Route>
            <Route path='/admin/feedback-analysis/review'>
              <FeedbackAnalysisReview />
            </Route>
            <Route path='/admin/feedback-analysis'>
              <FeedbackAnalysis />
            </Route>
            <Route path='/'>
              <Redirect to='/admin/sale-by-item' />
            </Route>
          </Switch>
        </Box>
      </Router>
    </Box>
  );
}

Admin.propTypes = {};

export default Admin;
