import React, { useState } from 'react';
import { Box, Button, Input, InputGroup, InputLeftAddon, InputRightElement } from '@chakra-ui/react';
import cookies from 'react-cookies';

function Settings(props) {
  const initialCustomerId = cookies.load('customerId');
  const [customerId, setCustomerId] = useState(initialCustomerId);
  const changeCustomerId = () => {
    cookies.save('customerId', customerId);
    window.location.href = '/login';
  };
  return (
    <Box p={'20px'}>
      <InputGroup>
        <InputLeftAddon children='Customer ID' />
        <Input type='text' value={customerId} onChange={e => setCustomerId(e.target.value)} />
        {initialCustomerId !== customerId ? (
          <InputRightElement width='4.5rem'>
            <Button h='1.75rem' size='sm' onClick={() => changeCustomerId()}>
              Save
            </Button>
          </InputRightElement>
        ) : null}
      </InputGroup>
    </Box>
  );
}

Settings.propTypes = {};

export default Settings;
