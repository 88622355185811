import React from 'react';
import PropTypes from 'prop-types';
import { Table, Tbody, Td, Th, Thead, Tr } from '@chakra-ui/react';

function MainTable(props) {
  const { dashboardSummary, companyNameMap, onRowClick } = props;
  return (
    <Table variant='simple'>
      <Thead>
        <Tr>
          <Th>RCID</Th>
          <Th>Company</Th>
          <Th>Total Branch</Th>
          <Th>Connected Branch</Th>
          <Th>Total Data</Th>
          <Th>Not Sent</Th>
          <Th>Sent</Th>
          <Th>Acknowledged</Th>
          <Th>Cancelled</Th>
          <Th>Duplicate</Th>
        </Tr>
      </Thead>
      <Tbody>
        {dashboardSummary && companyNameMap && dashboardSummary.map((row, index) => {
          return (
            <Tr key={index} cursor={'pointer'} _hover={{
              backgroundColor: 'gray.200',
            }}
                onClick={() => onRowClick({ headOffice: row.headOffice })}>
              <Td>{row.headOffice}</Td>
              <Td>{companyNameMap[`${row.headOffice}-${row.headOffice}`] ? companyNameMap[`${row.headOffice}-${row.headOffice}`].companyName : null}</Td>
              <Td>{row.totalBranches}</Td>
              <Td>{row.connected}</Td>
              <Td>{row.totalData}</Td>
              <Td>{row.notSent}</Td>
              <Td>{row.sent}</Td>
              <Td>{row.acknowledged}</Td>
              <Td>{row.cancelled}</Td>
              <Td>{row.duplicate}</Td>
            </Tr>
          );
        })}
      </Tbody>
    </Table>
  );
}

MainTable.propTypes = {
  dashboardSummary: PropTypes.array,
  companyNameMap: PropTypes.object,
  onRowClick: PropTypes.func,
};

export default MainTable;
