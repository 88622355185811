import React, { useEffect, useState } from 'react';
import { Box } from '@chakra-ui/react';
import cookies from 'react-cookies';
import Loader from '../../components/Loader/Loader';
import DataTable from '../../components/DataTable/DataTable';
import useGetTableData from '../../hooks/useGetTableData';
import Controls from '../../components/Controls/Controls';
import { Doughnut } from 'react-chartjs-2';

function SaleByLocation() {

  const REPORT_ID = 36;
  const [duration, setDuration] = useState(cookies.load('lastSelectedInterval') ? cookies.load('lastSelectedInterval').value : 0);
  const [company, setCompany] = useState(cookies.load('lastSelectedCompany') ? cookies.load('lastSelectedCompany').value : 0);
  const [isDetailed, setIsDetailed] = useState(false);

  const [chartData, setChartData] = useState({});
  const [isChartVisible, setIsChartVisible] = useState(true);

  const { tableData, isLoading } = useGetTableData({ reportId: REPORT_ID, location: company, duration });

  useEffect(() => {
    const labels = [];
    const data = [];
    const detailedData = [];
    tableData.map(dataItem => {
      labels.push(dataItem.LocationName);
      data.push(dataItem.SaleAmount);
      detailedData.push(dataItem.NetAmount);
      return dataItem;
    });
    setChartData({
      labels,
      datasets: [
        {
          label: isDetailed ? 'S - SR' : 'Amt',
          data: isDetailed ? detailedData : data,
          backgroundColor: [
            'rgba(255, 99, 132, 0.2)',
            'rgba(54, 162, 235, 0.2)',
            'rgba(255, 206, 86, 0.2)',
            'rgba(75, 192, 192, 0.2)',
            'rgba(153, 102, 255, 0.2)',
            'rgba(255, 159, 64, 0.2)',
          ],
          borderColor: [
            'rgba(255, 99, 132, 1)',
            'rgba(54, 162, 235, 1)',
            'rgba(255, 206, 86, 1)',
            'rgba(75, 192, 192, 1)',
            'rgba(153, 102, 255, 1)',
            'rgba(255, 159, 64, 1)',
          ],
          borderWidth: 1,
        },
      ],
    });
  }, [tableData, isDetailed]);

  return (
    <Box p={'20px'}>
      <Loader isVisible={isLoading} />

      <Controls
        onCompanySelect={setCompany}
        onIntervalSelect={setDuration}
        intervalValuesToHide={[9]}
        onIsDetailedSwitch={setIsDetailed}
        onChartVisibilitySwitch={setIsChartVisible}
        isChartDefault={true}
      />

      {isChartVisible ? (
        <Doughnut data={chartData} />
      ) : (
        <DataTable tableData={tableData}
                   reportId={REPORT_ID}
                   isDetailed={isDetailed}
        />
      )}
    </Box>
  );
}

SaleByLocation.propTypes = {};

export default SaleByLocation;
