import React from 'react';
import PropTypes from 'prop-types';
import { Box } from '@chakra-ui/react';

function AlphaWidget(props) {
  const { title, subtitle } = props;
  return (
    <Box>
      <Box fontSize={14}>{title}</Box>
      <Box fontSize={16} mt={2}
           fontWeight={'bold'}>{subtitle}</Box>
    </Box>
  );
}

AlphaWidget.propTypes = {
  title: PropTypes.string,
  subtitle: PropTypes.any,
};

export default AlphaWidget;
