import React from 'react';
import PropTypes from 'prop-types';
import { Box, Flex, Icon } from '@chakra-ui/react';
import { BsChevronRight } from 'react-icons/all';
import PercentageBar from '../../components/PercentageBar/PercentageBar';
import { getColorByPercentage } from '../../utils';

function SummaryBar(props) {
  const { title, count, icon, percentage, subTitle } = props;
  return (
    <Flex>
      <Icon as={icon} boxSize={10} />
      <Box flex={1} ml={4}>
        <Box fontSize={16}>{title} ({count})</Box>
        <Box fontSize={14}>{subTitle}</Box>
        <Flex alignItems={'center'}>
          <PercentageBar percentage={percentage} />
          <Box fontSize={12} ml={4} color={getColorByPercentage(percentage)}>{percentage}%</Box>
        </Flex>
      </Box>
      <Icon as={BsChevronRight} />
    </Flex>
  );
}

SummaryBar.propTypes = {
  title: PropTypes.string,
  subTitle: PropTypes.string,
  count: PropTypes.number,
  percentage: PropTypes.number,
  icon: PropTypes.any,
};

export default SummaryBar;
