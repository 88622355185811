import { MdApps, MdAttachMoney, MdSettings, MdShoppingCart } from 'react-icons/md';

export const COLORS = {
  BLUE: '#233044',
};

export const getReportHeaders = ({ id }) => {
  const headers = {
    35: {
      leftStickyColumnCount: 1,
      normal: ['ProductName', 'Quantity', 'SaleValue'],
      detailed: ['ProductName', 'SubGroupName', 'Quantity', 'SaleValue', 'Tax', 'Discount'],
      nameMap: {
        ProductID: 'Code',
        ProductName: 'Item',
        SubGroupName: 'Sub Group',
        BrandName: 'Brand',
        Quantity: 'Qty',
        SaleValue: 'Amt',
        PurValue: '',
        AvgRate: 'Avg',
        Tax: 'Tax',
        Discount: 'Disc',
        ProfitLoss: '',
        MarginPer: '',
        MarkupPer: '',
        StockQty: '',
        StockValue: '',
      },
      totals: {
        SaleValue: {
          fn: 'sum',
          title: '',
        },
      },
      forceString: [],
      numericColumns: ['Quantity', 'SaleValue', 'Tax', 'Discount'],
    },
    37: {
      leftStickyColumnCount: 1,
      normal: ['VchDate', 'Memo', 'NetSales', 'Discount', 'Tax', 'Target', 'AchvPer'],
      detailed: ['VchDate', 'Memo', 'NetSales'],
      nameMap: {
        LocationName: 'Location',
        VchDate: 'Date',
        NetSales: 'Sale',
        Discount: 'Disc',
        AchvPer: '%',
      },
      totals: {
        VchDate: {
          fn: 'count',
          title: 'days: ',
        },
      },
      forceString: [],
      numericColumns: ['Memo', 'NetSales', 'Discount', 'Tax'],
    },
    45: {
      leftStickyColumnCount: 0,
      normal: ['VchTime', 'VoucherNumber', 'CustomerName', 'QtyTotal', 'BillAmount'],
      detailed: ['VoucherNumber', 'CustomerName', 'QtyTotal', 'BillAmount', 'VchTime', 'VchDate', 'SerialNumber'],
      nameMap: {
        VoucherNumber: 'Memo',
        CustomerName: 'Customer',
        QtyTotal: 'Qty',
        BillAmount: 'Amount',
        VchTime: 'Time',
        VchDate: 'Date',
      },
      totals: {},
      forceString: ['VoucherNumber'],
      numericColumns: ['QtyTotal', 'BillAmount'],
    },
    44: {
      leftStickyColumnCount: 0,
      normal: ['Hour', 'ThisMonth', 'LastMonth'],
      detailed: [],
      nameMap: { Hour: '', ThisMonth: 'This Month', LastMonth: 'Last Month' },
      totals: {
        Hour: {
          fn: 'count',
          title: 'Hrs: ',
        },
      },
      forceString: [],
      numericColumns: ['ThisMonth', 'LastMonth'],
    },
    32: {
      leftStickyColumnCount: 1,
      normal: ['WeekDayName', 'ThisWeek', 'LastWeek', 'ThisMonth', 'LastMonth'],
      detailed: [],
      nameMap: {
        WeekDayName: 'Day',
        DayInWeek: '',
        ThisMonth: 'This Month',
        LastMonth: 'Last Month',
        ThisWeek: 'This Week',
        LastWeek: 'Last Week',
      },
      totals: {},
      forceString: [],
      numericColumns: ['ThisWeek', 'LastWeek', 'ThisMonth', 'LastMonth'],
    },
    8: {
      leftStickyColumnCount: 0,
      normal: ['BillSize', 'MemoCount', 'MemoPercent', 'Amount', 'AmountPercent', 'SalePriceRange', 'Qty'],
      detailed: [],
      nameMap: {
        BillSize: 'Bill Size',
        MemoCount: 'Memo',
        MemoPercent: '%',
        Amount: 'Amt',
        AmountPercent: '%',
        SalePriceRange: 'SP Range',
        Qty: '',
      },
      totals: {},
      forceString: [],
      numericColumns: [],
    },
    36: {
      leftStickyColumnCount: 0,
      normal: ['LocationName', 'SaleMemo', 'SaleAmount'],
      detailed: ['LocationName', 'SaleMemo', 'SaleAmount', 'SRMemo', 'SRAmount', 'NetAmount', 'Disc', 'Tax', 'NetAmount_Excl_Tax', 'Cash'],
      nameMap: {
        LocationName: 'Location',
        SaleMemo: 'Memo',
        SaleAmount: 'Amount',
        SRMemo: 'SR Memo',
        SRAmount: 'SR Amt',
        NetAmount: 'S - SR',
        Disc: '',
        Tax: '',
        SharePer: '',
        NetAmount_Excl_Tax: 'Amt - Tax',
        LocationID: '',
        Cash: '',
      },
      totals: {},
      forceString: [],
      numericColumns: ['SaleMemo', 'SaleAmount', 'SRMemo', 'SRAmount', 'NetAmount', 'Disc', 'Tax', 'NetAmount_Excl_Tax', 'Cash'],
    },
    3: {
      leftStickyColumnCount: 1,
      normal: ['ProductGroupName', 'SubGroupName', 'Today', 'MTD', 'YTD'],
      detailed: ['ProductGroupName', 'SubGroupName', 'Today', 'TodayPercent', 'MTD', 'MTDPercent', 'YTD', 'YTDPercent'],
      nameMap: {
        ProductGroupName: 'Group',
        SubGroupName: 'SubGroup',
        Today: '',
        TodayPercent: '%',
        MTD: '',
        MTDPercent: '%',
        YTD: '',
        YTDPercent: '%',
      },
      totals: {},
      forceString: [],
      numericColumns: ['Today', 'MTD', 'YTD'],
    },
    6: {
      leftStickyColumnCount: 1,
      normal: ['Particulars', 'Today', 'MTD', 'YTD'],
      detailed: [],
      nameMap: {},
      totals: {},
      forceString: [],
      numericColumns: ['Today', 'MTD', 'YTD'],
    },
    43: {
      leftStickyColumnCount: 0,
      normal: ['User', 'Total', 'TIPTotal', 'Cash', 'CreditCard', 'CreditNoteIssued', 'OnlinePayment', 'Disc', 'ROAmt', 'OtherCharges'],
      detailed: ['User', 'Total', 'TIPTotal', 'Cash', 'CreditCard', 'CreditNoteIssued', 'OnlinePayment', 'Disc', 'ROAmt', 'OtherCharges'],
      nameMap: {
        User: '',
        Total: '',
        TIPTotal: 'Tip',
        Cash: '',
        CreditCard: 'Card',
        CreditNoteIssued: 'C-Note',
        OnlinePayment: 'Online',
        Disc: 'Disc',
        ROAmt: 'R-off',
        OtherCharges: 'O-Charge',
      },
      totals: {},
      forceString: [],
      numericColumns: ['Total', 'TIPTotal', 'Cash', 'CreditCard', 'CreditNoteIssued', 'OnlinePayment', 'Disc', 'ROAmt', 'OtherCharges'],
    },
    2: {
      leftStickyColumnCount: 0,
      normal: ['Particulars', 'Value'],
      detailed: [],
      nameMap: {},
      totals: {},
      forceString: [],
      numericColumns: ['Value'],
    },
    4: {
      leftStickyColumnCount: 1,
      normal: ['TransactionType', 'TodayAmt', 'TodayMemo', 'TodayQty', 'MTDAmt', 'MTDMemo', 'MTDQty', 'YTDAmt', 'YTDMemo', 'YTDQty'],
      detailed: [],
      nameMap: {
        TransactionType: 'Transaction',
        TodayAmt: 'Today',
        TodayMemo: 'Memo',
        TodayQty: 'Qty',
        MTDAmt: 'MTD',
        MTDMemo: 'Memo',
        MTDQty: 'Qty',
        YTDAmt: 'YDT',
        YTDMemo: 'Memo',
        YTDQty: 'Qty',
      },
      totals: {},
      forceString: [],
      numericColumns: ['TodayAmt', 'TodayMemo', 'TodayQty', 'MTDAmt', 'MTDMemo', 'MTDQty', 'YTDAmt', 'YTDMemo', 'YTDQty'],
    },
    46: {
      leftStickyColumnCount: 1,
      normal: ['Sales', 'BreakFastAmount', 'LunchAmount', 'SnacksAmount', 'DinnerAmount', 'Total'],
      detailed: [],
      nameMap: {
        Sales: '',
        BreakFastAmount: 'B/F',
        LunchAmount: 'Lunch',
        SnacksAmount: 'Snacks',
        DinnerAmount: 'Dinner',
        Total: '',
      },
      totals: {},
      forceString: [],
      numericColumns: ['BreakFastAmount', 'LunchAmount', 'SnacksAmount', 'DinnerAmount', 'Total'],
    },
    39: {
      leftStickyColumnCount: 1,
      normal: ['ProductName', 'StkQty'],
      detailed: ['ProductName', 'SubGroupName', 'Rate', 'StkValue', 'StkQty', 'PendingSO', 'PendingPO', 'NetStk'],
      nameMap: {
        ProductName: 'Item',
        SubGroupName: 'Sub Grp',
        BrandName: 'Brand',
        ProductID: 'Id',
        StkQty: 'Stk',
        StkValue: 'Amt',
        Rate: '',
        PendingSO: 'SO',
        PendingPO: 'PO',
        NetStk: 'Nett',
      },
      totals: {},
      forceString: [],
      numericColumns: ['Rate', 'StkValue', 'StkQty', 'PendingSO', 'PendingPO', 'NetStk'],
    },
    40: {
      leftStickyColumnCount: 1,
      normal: ['AccountName', 'NoOfMemo', 'Qty', 'TotalPurchase', 'DueAmount', 'SaleValue', 'StkValue', 'LastPurDate', 'LastPmntDate'],
      detailed: ['AccountID', 'AccountName', 'NoOfMemo', 'Qty', 'TotalPurchase', 'DueAmount', 'SaleValue', 'StkValue', 'LastPurDate', 'LastPmntDate'],
      nameMap: {
        AccountID: 'Id',
        AccountName: 'Supplier',
        NoOfMemo: 'Memo',
        Qty: '',
        TotalPurchase: 'Tot Pur',
        DueAmount: 'Due',
        SaleValue: 'Sale',
        StkValue: 'Stock',
        LastPurDate: 'Last Pur',
        LastPmntDate: 'Last Pay',
      },
      totals: {
        AccountName: {
          fn: 'count',
          title: 'Count: ',
        },
      },
      forceString: [],
      numericColumns: ['NoOfMemo', 'Qty', 'TotalPurchase', 'DueAmount', 'SaleValue', 'StkValue'],
    },
    5: {
      leftStickyColumnCount: 0,
      normal: ['Account', 'Group', 'Closing'],
      detailed: ['Account', 'Group', 'Opening', 'Debit', 'Credit', 'Closing'],
      nameMap: {},
      totals: {},
      forceString: [],
      numericColumns: ['Opening', 'Debit', 'Credit', 'Closing'],
    },
  };

  return headers[id];
};

export const adminMenu = [
  {
    name: 'Sale',
    icon: <MdShoppingCart size={'20px'} color={'#888F99'} />,
    subMenu: [
      {
        name: 'Sale by Item',
        link: '/admin/sale-by-item',
      },
      {
        name: 'Sale by Category',
        link: '/admin/sale-by-category',
      },
      {
        name: 'Sale Register',
        link: '/admin/sale-register',
      },
      {
        name: 'Sale by Voucher',
        link: '/admin/sale-by-voucher',
      },
      {
        name: 'Sale by Hour',
        link: '/admin/sale-by-hour',
      },
      {
        name: 'Sale by Weekday',
        link: '/admin/sale-by-weekday',
      },
      {
        name: 'Customer Mix Analysis',
        link: '/admin/customer-mix-analysis',
      },
      {
        name: 'Sale by Location',
        link: '/admin/sale-by-location',
      },
    ],
  },
  {
    name: 'MIS',
    icon: <MdApps size={'20px'} color={'#888F99'} />,
    subMenu: [
      {
        name: 'Sale KPI',
        link: '/admin/sale-kpi',
      },
      {
        name: 'Day End Report',
        link: '/admin/day-end-report',
      },
      {
        name: 'Length of Seating',
        link: '/admin/length-of-seating',
      },
      {
        name: 'Transaction Analysis',
        link: '/admin/transaction-analysis',
      },
      {
        name: 'Sale by Shift',
        link: '/admin/sale-by-shift',
      },
      {
        name: 'Stock Statement',
        link: '/admin/stock-statement',
      },
      {
        name: 'Supplier Performance Statement',
        link: '/admin/supplier-performance-statement',
      },
      {
        name: 'Feedback Analysis',
        link: '/admin/feedback-analysis',
        hideTopbarTitle: true,
      },
    ],
  },
  {
    name: 'Finance',
    icon: <MdAttachMoney size={'20px'} color={'#888F99'} />,
    subMenu: [
      {
        name: 'Bank and Cash Book',
        link: '/admin/bank-and-cashbook',
      },
    ],
  },
  {
    name: 'Settings',
    icon: <MdSettings size={'20px'} color={'#888F99'} />,
    subMenu: [
      {
        name: 'Settings',
        link: '/admin/settings',
      },
    ],
  },

];
