import React, { useEffect, useState } from 'react';
import { Box, Button, Flex, Input } from '@chakra-ui/react';
import { apiCall } from '../../api/base';
import Loader from '../../components/Loader/Loader';
import MainTable from './MainTable';
import DetailsTable from './DetailsTable';
import cookies from 'react-cookies';

function Dashboard() {
  const [isLoading, setIsLoading] = useState(false);
  const [password, setPassword] = useState('');
  const [tableData, setTableData] = useState({});
  const [companyNameMap, setCompanyNameMap] = useState({});
  const [detailsMap, setDetailsMap] = useState({});
  const [visibleDetailsId, setVisibleDetailsId] = useState(null);
  useEffect(() => {
    (async () => {
      setIsLoading(true);
      const response = await apiCall({
        url: `${process.env.REACT_APP_API_HOST}/api/admin-dashboard`,
        method: 'post',
        data: {
          'startDate': '2021-01-01',
          'endDate': '2021-07-28',
        },
        headers: {
          Authorization: 'Bearer eyJhbGciOiJIUzI1NiJ9.UmFuY2VMYWI.CpZ1FALdqvx3yJJte9nsvrdjdsCCJnR7GhouDOieasM',
        },
      });
      setIsLoading(false);
      setTableData(response);

      const companyNameMap_ = {};
      const detailsMap_ = {};
      response.dashboardDetails.map(item => {
        companyNameMap_[`${item.headOffice}-${item.branch}`] = item;
        if (detailsMap_[item.headOffice]) {
          detailsMap_[item.headOffice].push(item);
        } else {
          detailsMap_[item.headOffice] = [item];
        }
        return item;
      });
      setCompanyNameMap(companyNameMap_);
      setDetailsMap(detailsMap_);
    })();
  }, []);

  const handleRowClick = ({ headOffice }) => {
    setVisibleDetailsId(headOffice);
  };

  const handleLogin = () => {
    cookies.save('dashboardPassword', password);
    window.location.reload();
  };

  if (cookies.load('dashboardPassword') !== 'eyJhbG#ciO$iJIUzI1NiJ9') {
    return (
      <Box p={'20px'}>
        <Flex alignItems={'center'}>
          <Input placeholder={'Enter password'} maxW={300} value={password}
                 onChange={e => setPassword(e.target.value)} />
          <Button onClick={handleLogin} ml={4} colorScheme={'blue'}>Login</Button>
        </Flex>
      </Box>
    );
  }

  return (
    <Box p={'20px'}>
      <Loader isVisible={isLoading} />
      {visibleDetailsId ? (
        <DetailsTable data={detailsMap[visibleDetailsId]} backClicked={() => setVisibleDetailsId(null)} />
      ) : (
        <MainTable companyNameMap={companyNameMap} dashboardSummary={tableData.dashboardSummary}
                   onRowClick={handleRowClick} />
      )}
    </Box>
  );
}

Dashboard.propTypes = {};

export default Dashboard;
