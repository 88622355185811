import React, { useEffect, useState } from 'react';
import { Flex, Icon } from '@chakra-ui/react';
import { IoLocationOutline } from 'react-icons/all';
import PropTypes from 'prop-types';
import cookies from 'react-cookies';
import SelectionModal from '../SelectionModal/SelectionModal';

function LocationControl(props) {
  const { onSelect } = props;
  const [locations, setLocations] = useState([]);
  const [selectedLocation, setSelectedLocation] = useState(null);
  const [isModalOpen, setIsModalOpen] = useState(false);
  useEffect(() => {
    if (cookies.load('locationData')) {
      const locations_ = [];
      cookies.load('locationData').map(locationData => {
        locations_.push({
          value: locationData.LocationID,
          label: locationData.LocationName.trim(),
        });
        return locationData;
      });
      setLocations(locations_);

      const lastSelectedLocation = cookies.load('lastSelectedLocation') || locations_[0];
      setSelectedLocation(lastSelectedLocation);
    }
  }, []);

  return (
    <>
      <Flex alignItems={'center'} cursor={'pointer'} color={'white'} bg={'#410E90'} borderRadius={4} p={2} fontSize={14}
            whiteSpace={'nowrap'} h={10}
            onClick={() => {
              setIsModalOpen(true);
            }}>
        <Icon as={IoLocationOutline} color={'white'} mr={1} /> {selectedLocation && selectedLocation.label}
      </Flex>
      <SelectionModal options={locations} headerBg={'#410E90'} bg={'#F4F4F4'} title={'Select Location'}
                      searchPlaceholder={'Search Location'} selectionColor={'#410E90'}
                      selectedOption={selectedLocation && selectedLocation.value}
                      onOptionSelect={(option) => {
                        onSelect(option.value);
                        setIsModalOpen(false);
                        setSelectedLocation(option);
                        cookies.save('lastSelectedLocation', option);
                      }}
                      isOpen={isModalOpen}
                      isSearchable
                      onModalClose={() => {
                        setIsModalOpen(false);
                      }} />
    </>
  );
}

LocationControl.propTypes = {
  onSelect: PropTypes.func,
};

export default LocationControl;
