import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Flex, Icon } from '@chakra-ui/react';
import { AiOutlineBarChart, AiOutlineTable } from 'react-icons/all';

function ChartSwitch(props) {
  const { bg, onChange, isChartDefault } = props;
  const [isCharted, setIsCharted] = useState(isChartDefault);
  return (
    <Flex alignItems={'center'} cursor={'pointer'} color={'white'} bg={isCharted ? 'gray.500' : bg} borderRadius={4}
          p={2} fontSize={14}
          whiteSpace={'nowrap'} h={10}
          onClick={() => {
            setIsCharted(!isCharted);
            onChange(!isCharted);
          }}>
      <Icon as={isCharted ? AiOutlineBarChart : AiOutlineTable} color={'white'}
            mr={1} /> {isCharted ? 'Table' : 'Chart'}
    </Flex>
  );
}

ChartSwitch.propTypes = {
  onChange: PropTypes.func,
  bg: PropTypes.string,
  isChartDefault: PropTypes.bool,
};

export default ChartSwitch;
