import React, { useState } from 'react';
import {
  Box,
  Icon,
  Input,
  InputGroup,
  InputLeftElement,
  Modal,
  ModalBody,
  ModalContent,
  ModalOverlay,
} from '@chakra-ui/react';
import { AiOutlineSearch } from 'react-icons/all';
import PropTypes from 'prop-types';

function SelectionModal(props) {
  const {
    options,
    bg,
    title,
    searchPlaceholder,
    selectedOption,
    onOptionSelect,
    selectionColor,
    isOpen,
    onModalClose,
    headerBg,
    isSearchable,
  } = props;
  const [searchString, setSearchString] = useState('');
  const handleSearch = (e) => {
    setSearchString(e.target.value);
  };
  return (
    <Modal isOpen={isOpen} onClose={onModalClose}>
      <ModalOverlay />
      <ModalContent width={'fit-content'} borderRadius={0} minW={290}>
        <ModalBody p={0} bg={bg}>
          <Box bg={headerBg} color={'white'} fontSize={14} py={4} textAlign={'center'} px={4} fontWeight={'bold'}>
            {title}
          </Box>
          {isSearchable && (
            <InputGroup mt={1} h={9} bg={'white'}>
              <InputLeftElement
                pointerEvents='none'
                children={<Icon boxSize={3.5} as={AiOutlineSearch} />}
              />
              <Input fontSize={14} py={1} variant={'unstyled'} type='text' placeholder={searchPlaceholder}
                     value={searchString} onChange={handleSearch} />
            </InputGroup>
          )}
          <Box h={'60vh'} overflow={'auto'}>
            {options.map(option => {
              if (option.label.toLowerCase().search(searchString.toLowerCase()) > -1) {
                return (
                  <Box cursor={'pointer'}
                       fontWeight={selectedOption === option.value ? 'bold' : 'normal'}
                       color={selectedOption === option.value ? selectionColor : 'black'}
                       key={option.label} px={4} py={2.5} mt={1} bg={'white'}
                       fontSize={14}
                       onClick={() => {
                         onOptionSelect(option);
                       }}>
                    <Box>{option.label}</Box>
                    <Box>{option.label2}</Box>
                  </Box>
                );
              }
              return null;
            })}
          </Box>
        </ModalBody>
      </ModalContent>
    </Modal>
  );
}

SelectionModal.propTypes = {
  options: PropTypes.array,
  bg: PropTypes.string,
  title: PropTypes.string,
  searchPlaceholder: PropTypes.string,
  selectedOption: PropTypes.any,
  onOptionSelect: PropTypes.func,
  onModalClose: PropTypes.func,
  selectionColor: PropTypes.string,
  headerBg: PropTypes.string,
  isOpen: PropTypes.bool,
  isSearchable: PropTypes.bool,
};

export default SelectionModal;
