import React from 'react';
import PropTypes from 'prop-types';
import { Box, HStack } from '@chakra-ui/react';
import PercentageBar from '../../components/PercentageBar/PercentageBar';

function RatingBar(props) {
  const { rating, percentage, count } = props;
  return (
    <HStack spacing={4} alignItems={'center'} fontSize={12}>
      <Box fontWeight={'bold'}>★ {rating}</Box>
      <PercentageBar percentage={percentage} />
      <Box textAlign={'right'} fontWeight={'bold'} w={'60px'}>{percentage}% ({count})</Box>
    </HStack>
  );
}

RatingBar.propTypes = {
  rating: PropTypes.number,
  percentage: PropTypes.number,
  count: PropTypes.number,
};

export default RatingBar;
