import XMLParser from 'react-xml-parser';
import cookies from 'react-cookies';
import { isNaN } from 'formik';
import moment from 'moment';
import { adminMenu } from './constants';

export const getXml = (xml) => {
  return new XMLParser().parseFromString(xml);
};

export const getReportParams = ({ duration, option, location, company, reportName, strOptionalParameter }) => {
  return {
    teamID: cookies.load('teamId'),
    reportName: reportName,
    iDuration: duration || 0,
    iLocationID: location || company || 0,
    ioption: option || 0,
    financialYearStart: '01/01/2021',
    financialYearEnd: '12/31/2021',
    scaleValue: '1',
    strDayStartHr: '0',
    strOptionalParameter: strOptionalParameter,
  };
};

export const formatValue = ({ value }) => {
  if (!isNaN(parseFloat(value))) {
    const numberFormat = cookies.load('formats');
    return parseFloat(value).toLocaleString('en-IN', { maximumFractionDigits: numberFormat[6].DateTimeFormatValue });
  }
  if (value && value.startsWith('/Date')) {
    const timestamp = parseInt(value.substr(6, 13));
    const time = moment.utc(timestamp).add(5, 'hours').add(30, 'minutes').format('HH:mm');
    return moment.utc(timestamp).add(5, 'hours').add(30, 'minutes').format(time === '00:00' ? 'LL' : 'LLL');
  }
  return value;
};

export const aggregateRowData = ({ aggregator, rows, heading }) => {
  if (!aggregator) {
    return null;
  }
  const values = rows.map((row) => {
    return row.values[heading];
  });
  switch (aggregator.fn) {
    default:
      return null;
    case 'sum':
      return `${aggregator.title}${formatValue({ value: values.reduce((a, b) => a + b, 0) })}`;
    case 'count':
      return `${aggregator.title}${formatValue({ value: values.length })}`;
    case 'average':
      return `${aggregator.title}${formatValue({ value: values.reduce((a, b) => a + b, 0) / values.length })}`;
    case 'min':
      return `${aggregator.title}${formatValue({ value: Math.min(...values) })}`;
    case 'max':
      return `${aggregator.title}${formatValue({ value: Math.max(...values) })}`;
  }
};

export const getReportJsonFromApi = (response) => {
  return response.data.ExecuteMobiDataResult ? JSON.parse(response.data.ExecuteMobiDataResult[0]) : [];
};

export const getCurrentReportName = () => {
  let name = '';
  adminMenu.every(mainMenu => {
    mainMenu.subMenu.every(subItem => {
      if (subItem.link === window.location.pathname) {
        name = subItem.hideTopbarTitle ? '' : subItem.name;
        return false;
      }
      return true;
    });
    return true;
  });
  return name;
};

export const getAverageRating = ({
                                   scale1Ratings,
                                   scale2Ratings,
                                   scale3Ratings,
                                   scale4Ratings,
                                   scale5Ratings,
                                   totalScales,
                                 }) => {
  return parseFloat(((parseFloat(scale1Ratings) + parseFloat(scale2Ratings) + parseFloat(scale3Ratings) + parseFloat(scale4Ratings) + parseFloat(scale5Ratings)) / totalScales).toFixed(1));
};

export const getRatingByPercentage = (percentage) => {
  return parseFloat((percentage * 5 / 100).toFixed(1));
};


export const getColorByPercentage = (percentage) => {
  if (percentage < 30) {
    return 'red';
  }
  if (percentage < 80) {
    return 'yellow.500';
  }
  return 'green';
};
