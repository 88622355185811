import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { useSortBy, useTable } from 'react-table';
import { Cell, Row, StickyTable } from 'react-sticky-table';
import { Box } from '@chakra-ui/react';
import { aggregateRowData, formatValue } from '../../utils';
import { getReportHeaders } from '../../constants';
import { isIOS } from 'react-device-detect';

function DataTable(props) {
  const { tableData, isDetailed, reportId, isTotalRowVisible, onColumnClick } = props;

  const nameMap = getReportHeaders({ id: reportId }).nameMap;
  const numericColumns = getReportHeaders({ id: reportId }).numericColumns;
  const totalsMap = getReportHeaders({ id: reportId }).totals;
  const forceString = getReportHeaders({ id: reportId }).forceString;
  const leftStickyColumnCount = getReportHeaders({ id: reportId }).leftStickyColumnCount;

  const normalHeadersToShow = getReportHeaders({ id: reportId }).normal;
  const detailedHeadersToShow = getReportHeaders({ id: reportId }).detailed;
  const headersToShow = isDetailed ? detailedHeadersToShow : normalHeadersToShow;

  const data = React.useMemo(
    () => tableData,
    [tableData],
  );

  const sanitizedHeaders = headersToShow.map(header => {
    return {
      Header: nameMap[header] || header,
      accessor: header,
    };
  });

  const columns = React.useMemo(
    () => [...sanitizedHeaders],
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [isDetailed],
  );

  const tableInstance = useTable({ columns, data }, useSortBy);

  const {
    getTableProps,
    headerGroups,
    rows,
    prepareRow,
  } = tableInstance;

  const [lastScrollPos, setLastScrollPos] = useState(0);

  return (
    <>
      {tableData && tableData.length ? (
        <Box w={'100%'} h={isIOS ? 'calc(100vh - 240px)' : 'calc(100vh - 190px)'}>
          <StickyTable {...getTableProps()} leftStickyColumnCount={leftStickyColumnCount} borderWidth={'1px'}
                       headerZ={1} footerZ={1}
                       stickyFooterCount={1} style={{ fontSize: '12px' }}
                       onScroll={(event) => {
                         if (lastScrollPos > event.currentTarget.scrollTop) {
                           // dispatch({ type: 'top' });
                           setLastScrollPos(event.currentTarget.scrollTop);
                         } else if (lastScrollPos < event.currentTarget.scrollTop) {
                           // dispatch({ type: 'bottom' });
                           setLastScrollPos(event.currentTarget.scrollTop);
                         }
                       }}>
            {// Loop over the header rows
              headerGroups.map(headerGroup => (
                // Apply the header row props
                <Row {...headerGroup.getHeaderGroupProps()}>
                  {// Loop over the headers in each row
                    headerGroup.headers.map(column => {
                      if (Object.keys(tableData[0]).indexOf(column.id) === -1) {
                        return null;
                      }
                      return (
                        <Cell {...column.getHeaderProps(column.getSortByToggleProps())} p={1} textTransform={'none'}
                              style={{
                                fontWeight: 'bold',
                                textAlign: numericColumns.indexOf(column.id) > -1 ? 'right' : 'initial',
                              }}>
                          {column.render('Header')}
                        </Cell>
                      );
                    })}
                </Row>
              ))}

            {// Loop over the table rows
              rows.map(row => {
                // Prepare the row for display
                prepareRow(row);
                return (
                  // Apply the row props
                  <Row {...row.getRowProps()}>
                    {// Loop over the rows cells
                      row.cells.map(cell => {
                        if (Object.keys(tableData[0]).indexOf(cell.column.id) === -1) {
                          return null;
                        }
                        return (
                          <Cell
                            p={1}
                            {...cell.getCellProps()}
                            style={{
                              textAlign: numericColumns.indexOf(cell.column.id) > -1 ? 'right' : 'initial',
                              color: onColumnClick[cell.column.id] ? 'blue' : 'initial',
                            }}
                            onClick={() => onColumnClick[cell.column.id] ? onColumnClick[cell.column.id](cell.value) : null}>
                            {forceString.indexOf(cell.column.id) > -1 ? cell.value : formatValue({ value: cell.value })}
                          </Cell>
                        );
                      })}
                  </Row>
                );
              })}
            {isTotalRowVisible ? (
              <Row>
                {headersToShow.map((heading) => {
                  if (Object.keys(tableData[0]).indexOf(heading) === -1) {
                    return null;
                  }
                  if (headersToShow.indexOf(heading) > -1) {
                    return <Cell key={heading} style={{
                      backgroundColor: '#E2E8F0',
                      textAlign: numericColumns.indexOf(heading) > -1 ? 'right' : 'initial',
                    }}>
                      {aggregateRowData({
                        aggregator: totalsMap[heading] || (numericColumns.indexOf(heading) > -1 ? {
                          fn: 'sum',
                          title: '',
                        } : null),
                        rows,
                        heading,
                      })}
                    </Cell>;
                  }
                  return null;
                })}
              </Row>
            ) : null}
          </StickyTable>
        </Box>
      ) : (
        <Box p={'30px'} textAlign={'center'}>
          No records found
        </Box>
      )}
    </>
  );
}

DataTable.propTypes = {
  tableData: PropTypes.array,
  reportId: PropTypes.number,
  isDetailed: PropTypes.bool,
  isTotalRowVisible: PropTypes.bool,
  onColumnClick: PropTypes.object,
};

DataTable.defaultProps = {
  isTotalRowVisible: true,
  onColumnClick: {},
};

export default DataTable;
