import React from 'react';
import PropTypes from 'prop-types';
import { Checkbox, FormControl, FormErrorMessage, FormLabel, Input, Text } from '@chakra-ui/react';
import { Field } from 'formik';

function FormInput(props) {
  function getInputType(field) {
    switch (props.type) {
      case 'checkbox':
        return (
          <>
            <Checkbox {...field} id={props.field} defaultIsChecked><Text
              fontSize={'12px'}>{props.label}</Text></Checkbox>
          </>
        );
      default:
        return (
          <>
            <FormLabel m={0} fontSize={'12px'} htmlFor={props.field}>{props.label}</FormLabel>
            <Input {...field} id={props.field} variant={'flushed'} type={props.type} />
          </>
        );
    }
  }

  return (
    <Field name={props.field} validate={props.validators[props.field]}>
      {({ field, form }) => (
        <FormControl isInvalid={form.errors[props.field] && form.touched[props.field]}>
          {getInputType(field)}
          <FormErrorMessage fontSize={'12px'}>{form.errors[props.field]}</FormErrorMessage>
        </FormControl>
      )}
    </Field>
  );
}

FormInput.propTypes = {
  validators: PropTypes.object,
  field: PropTypes.string,
  label: PropTypes.string,
};

export default FormInput;
