import { post } from '../api/base';
import { getReportJsonFromApi, getReportParams } from '../utils';
import { useEffect, useState } from 'react';
import swal from 'sweetalert';

export default function useGetTableData({
                                          reportId,
                                          duration,
                                          location,
                                          option,
                                          company,
                                          feedback,
                                          dateRange,
                                          clickedDate,
                                          shouldGetRawResponse,
                                        }) {
  const [tableData, setTableData] = useState([]);
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    (async () => {
      setIsLoading(true);
      const params = {
        duration,
        location,
        option,
        reportName: reportId,
      };

      const extraParams = {};

      if (dateRange) {
        extraParams['startdateRange'] = dateRange.split('|')[0];
        extraParams['enddateRange'] = dateRange.split('|')[1];
      }

      if (clickedDate) {
        extraParams['clickedDate'] = clickedDate;
      }

      if (feedback) {
        extraParams['FeedbackID'] = feedback;
      }

      params['strOptionalParameter'] = JSON.stringify(extraParams).replaceAll('"', '\'');

      let response;

      try {
        response = await post({
          method: 'GetReportData', params: getReportParams(params),
        });
      } catch (e) {
        setIsLoading(false);
        swal({
          icon: 'error',
          title: 'Some error occurred. Please get in touch with our customer support.',
        });
        return;
      }
      setIsLoading(false);
      setTableData(shouldGetRawResponse ? response.data : getReportJsonFromApi(response));
    })();
  }, [option, location, duration, reportId, company, feedback, dateRange, clickedDate, shouldGetRawResponse]);

  return { tableData, isLoading };
}
