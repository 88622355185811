import React, { useState } from 'react';
import { Box } from '@chakra-ui/react';
import cookies from 'react-cookies';
import Loader from '../../components/Loader/Loader';
import DataTable from '../../components/DataTable/DataTable';
import useGetTableData from '../../hooks/useGetTableData';
import Controls from '../../components/Controls/Controls';

function SaleByItem() {

  const REPORT_ID = 35;

  const [location, setLocation] = useState(cookies.load('lastSelectedLocation') ? cookies.load('lastSelectedLocation').value : 0);
  const [duration, setDuration] = useState(cookies.load('lastSelectedInterval') ? cookies.load('lastSelectedInterval').value : 0);
  const [isDetailed, setIsDetailed] = useState(false);

  const { tableData, isLoading } = useGetTableData({ reportId: REPORT_ID, duration, location });

  return (
    <Box p={'10px'}>
      <Loader isVisible={isLoading} />
      <Controls onLocationSelect={setLocation}
                onIntervalSelect={setDuration}
                intervalValuesToHide={[9]}
                onIsDetailedSwitch={setIsDetailed} />
      <DataTable tableData={tableData}
                 reportId={REPORT_ID}
                 isDetailed={isDetailed}
      />
    </Box>
  );
}

SaleByItem.propTypes = {};

export default SaleByItem;
