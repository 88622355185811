import React from 'react';
import PropTypes from 'prop-types';
import { Box, Flex, Text, useBreakpointValue } from '@chakra-ui/react';
import { adminMenu, COLORS } from '../../constants';
import MainItem from './MainItem';
import { MdPermIdentity, MdPowerSettingsNew } from 'react-icons/md';
import cookies from 'react-cookies';

function Sidebar(props) {
  const isMobile = useBreakpointValue({ base: true, md: false });
  const logout = () => {
    cookies.remove('userName');
    window.location.href = '/login';
  };
  return (
    <Flex flexDir={'column'} position={'fixed'} top={0} transition={'all 0.3s'}
          left={props.isSidebarCollapsed && isMobile ? '-280px' : 0} height={'100vh'} w={'280px'}
          backgroundColor={COLORS.BLUE} zIndex={4}
    >
      <Box color={'white'} p={'10px'} textAlign={'center'} fontSize={'20px'} fontWeight={'bold'}
           w={'100%'} backgroundColor={COLORS.BLUE}>
        RanceLab Mobi
      </Box>
      <Box flex={1} maxH={'100%'} overflow={'auto'}>
        {adminMenu.map(menuItem => <MainItem key={menuItem.name} menuItem={menuItem}
                                             menuClicked={props.menuClicked} />)}
      </Box>
      <Flex color={'white'} px={'20px'} textAlign={'center'} fontSize={'13px'} h={'60px'}
            w={'100%'} backgroundColor={'#1e2a38'} alignItems={'center'} justifyContent={'space-between'}>
        <Flex>
          <MdPermIdentity /> <Text marginLeft={'20px'}>{cookies.load('userName')}</Text>
        </Flex>
        <MdPowerSettingsNew size={20} onClick={logout} />
      </Flex>
    </Flex>
  );
}

Sidebar.propTypes = {
  isSidebarCollapsed: PropTypes.bool,
  menuClicked: PropTypes.func,
};

export default Sidebar;
