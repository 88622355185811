import React from 'react';
import PropTypes from 'prop-types';
import { Box, Flex } from '@chakra-ui/react';
import { MdMenu } from 'react-icons/md';
import { getCurrentReportName } from '../../utils';

function Topbar(props) {
  return (
    <Flex pl={{ base: 0, md: '280px' }} pos={'fixed'} backgroundColor={'white'} height={'64px'} w={'100%'}
          top={0} left={0} transition={'all 0.3s'} boxShadow={'0 0 5px 0px #e2e2e2'} alignItems={'center'}
          justify={'space-between'} zIndex={2}>
      <Flex alignItems={'center'}>
        <Box p={'12px'} display={{ base: 'inline-flex', md: 'none' }}>
          <MdMenu size={'22px'} onClick={props.handleMenuClick} />
        </Box>
        <Box ml={2} fontWeight={'bold'} whiteSpace={'nowrap'} overflow={'hidden'} textOverflow={'ellipsis'}
             fontSize={14} maxW={'40vw'}>
          {getCurrentReportName()}
        </Box>
      </Flex>
    </Flex>
  );
}

Topbar.propTypes = {
  handleMenuClick: PropTypes.func,
  timestamp: PropTypes.number,
};

export default Topbar;
