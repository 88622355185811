import React, { useEffect, useState } from 'react';
import Loader from '../../components/Loader/Loader';
import { Box, Center, Divider, HStack, Icon, Stack } from '@chakra-ui/react';
import useGetTableData from '../../hooks/useGetTableData';
import RatingBar from './RatingBar';
import { BsChevronRight, ImHappy, ImNeutral, ImSad } from 'react-icons/all';
import SummaryBar from './SummaryBar';
import AlphaWidget from './AlphaWidget';
import { Link } from 'react-router-dom';
import Controls from '../../components/Controls/Controls';
import cookies from 'react-cookies';

function FeedbackAnalysis(props) {
  const REPORT_ID = 70;

  const [location, setLocation] = useState(cookies.load('lastSelectedLocation') ? cookies.load('lastSelectedLocation').value : 0);
  const [locationRatings, setLocationRatings] = useState([]);

  console.log(cookies.load('lastSelectedInterval'));
  const [duration, setDuration] = useState(cookies.load('lastSelectedInterval')
    ? (cookies.load('lastSelectedInterval').value === 8 ? cookies.load('lastSelectedInterval').range : cookies.load('lastSelectedInterval').value)
    : 0);

  const [feedback, setFeedback] = useState(cookies.load('lastSelectedFeedback') ? cookies.load('lastSelectedFeedback').value : 2);

  const [scaleData, setScaleData] = useState(null);

  const { tableData, isLoading } = useGetTableData({
    reportId: REPORT_ID,
    duration: isNaN(duration) ? 8 : duration,
    location,
    feedback,
    dateRange: isNaN(duration) ? duration : undefined,
    shouldGetRawResponse: true,
  });

  useEffect(() => {
    if (tableData.ExecuteMobiDataResult) {
      setLocationRatings(JSON.parse(tableData.ExecuteMobiDataResult[0]));
      const selectedLocation = cookies.load('lastSelectedLocation') ? cookies.load('lastSelectedLocation').label : 'All Location';
      const scaleData_ = JSON.parse(tableData.ExecuteMobiDataResult[0]).filter(locationData => {
        return locationData.LocationName === selectedLocation;
      })[0];
      setScaleData(scaleData_);
    }
  }, [tableData]);

  return (
    <>
      <Box p={'20px'} pos={'relative'}>
        <Loader isVisible={isLoading} />

        <Controls
          onFeedbackSelect={setFeedback}
          onLocationWithRatingsSelect={setLocation}
          onIntervalSelect={setDuration}
          intervalValuesToHide={[9]}
          locationRatings={locationRatings}
          showDateRange
        />

        {scaleData && <HStack spacing={6} pos={'absolute'} right={5} top={4} alignItems={'flex-start'}>
          <AlphaWidget title={'Rating'} subtitle={`★ ${scaleData.Rating}`} />
          <AlphaWidget title={'Order'} subtitle={scaleData.Orders} />
          <AlphaWidget title={'Feedback'}
                       subtitle={`${scaleData.TotalFeedback} ${scaleData.FeedbackPercent ? (`(${scaleData.FeedbackPercent}%)`) : ''}`}
          />
        </HStack>}
      </Box>
      <Divider />
      {scaleData && (
        <>
          <Box p={'20px'}>
            <Stack spacing={4}>
              {[1, 2, 3, 4, 5].map(scale => {
                if (!scaleData[`Scale${scale}Name`]) {
                  return null;
                }
                return (
                  <RatingBar key={scale} rating={scaleData[`Scale${scale}Avg`]}
                             title={scaleData[`Scale${scale}Name`]}
                             percentage={scaleData[`Scale${scale}Avg`] * 20} />
                );
              })}
            </Stack>

            <Center>
              <Box as={Link} to={`/admin/feedback-analysis/ratings?duration=${duration}&feedback=${feedback}`}
                   fontSize={14} mt={4}>
                View in Detail <Icon as={BsChevronRight} />
              </Box>
            </Center>

          </Box>
          <Divider />

          <Box p={'20px'} d={'block'} as={Link}
               to={`/admin/feedback-analysis/reviews?location=${location}&feedback=${feedback}&duration=${duration}&type=Low`}>
            <SummaryBar percentage={scaleData.LowRatedOrderPercent} title={'Low Rated Order'}
                        count={scaleData.LowRatedOrderCount} icon={ImSad}
                        subTitle={scaleData.LowRatedOrderCaption} />
          </Box>
          <Divider />
          <Box p={'20px'} d={'block'} as={Link}
               to={`/admin/feedback-analysis/reviews?location=${location}&feedback=${feedback}&duration=${duration}&type=Average`}>
            <SummaryBar percentage={scaleData.AverageRatedOrderPercent} title={'Average Rated Order'}
                        count={scaleData.AverageRatedOrderCount} icon={ImNeutral}
                        subTitle={scaleData.AverageRatedOrderCaption} />
          </Box>
          <Divider />
          <Box p={'20px'} d={'block'} as={Link}
               to={`/admin/feedback-analysis/reviews?location=${location}&feedback=${feedback}&duration=${duration}&type=High`}>
            <SummaryBar percentage={scaleData.HighwRatedOrderPercent} title={'High Rated Order'}
                        count={scaleData.HighRatedOrderCount} icon={ImHappy}
                        subTitle={scaleData.HighRatedOrderCaption} />
          </Box>
          <Divider />
        </>
      )}
    </>
  );
}

FeedbackAnalysis.propTypes = {};

export default FeedbackAnalysis;
