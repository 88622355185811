import React from 'react';
import PropTypes from 'prop-types';
import { Box } from '@chakra-ui/react';
import { getColorByPercentage } from '../../utils';

function PercentageBar(props) {
  const { percentage } = props;
  return (
    <Box bg={'#CCCCCC'} flex={1} h={1} borderRadius={2} overflow={'hidden'} pos={'relative'}>
      <Box w={`${percentage}%`} bg={getColorByPercentage(percentage)} h={'100%'} borderRadius={2} pos={'absolute'}
           left={0} top={0} />
    </Box>
  );
}

PercentageBar.propTypes = {
  percentage: PropTypes.number,
};

export default PercentageBar;
