import React, { useState } from 'react';
import { Box, Button, Flex, Input, InputGroup, InputLeftAddon, InputRightElement } from '@chakra-ui/react';
import { Form, Formik } from 'formik';
import FormInput from '../../components/FormField/FormInput';
import { post } from '../../api/base';
import { getXml } from '../../utils';
import cookies from 'react-cookies';
import { useHistory } from 'react-router-dom';

function Login() {
  const history = useHistory();
  const [loginError, setLoginError] = useState('');
  const validators = {
    username: (value) => {
      let error;
      if (!value) {
        error = 'Username is required';
      }
      return error;
    },
    password: (value) => {
      let error;
      if (!value) {
        error = 'Password is required';
      }
      return error;
    },
    remember: () => {
      return null;
    },
  };

  const initialCustomerId = cookies.load('customerId');
  const [customerId, setCustomerId] = useState(initialCustomerId);
  const [isCustomerIdInputVisible, setIsCustomerIdInputVisible] = useState(false);
  const changeCustomerId = () => {
    cookies.save('customerId', customerId);
    window.location.href = '/login';
  };

  return (
    <Flex justify='center' h={'100vh'} align={'center'} backgroundColor={'#f7f9fc'}>
      <Box backgroundColor={'#fff'} p={'20px'} w={300}>
        <Box fontSize='20px' mb='40px' textAlign={'center'}>Login</Box>
        <Formik
          initialValues={{
            username: !initialCustomerId || (initialCustomerId && initialCustomerId === 'WZK-TRH') ? 'adm' : '',
            password: !initialCustomerId || (initialCustomerId && initialCustomerId === 'WZK-TRH') ? 'adm' : '',
            remember: true,
          }}
          onSubmit={async (values, actions) => {
            let loginResponse = null;
            try {
              loginResponse = await post({
                method: 'ValidateLoginMobi', params: {
                  userName: values.username,
                  password: values.password,
                },
              });
            } catch (e) {
              setLoginError(`We are unable to connect to RCID: ${cookies.load('customerId') || 'WZK-TRH'}. Please make sure it is accessible. Please update the RCID if required.`);
              setIsCustomerIdInputVisible(true);
              return;
            }

            if (loginResponse.data.ExecuteMobiDataResult[0] === '') {
              setLoginError('The user name or password is incorrect. Please try again.');
              return;
            }

            const userFullName = getXml(loginResponse.data.ExecuteMobiDataResult[0]).getElementsByTagName('PrintName')[0].value;
            const designationId = getXml(loginResponse.data.ExecuteMobiDataResult[0]).getElementsByTagName('DesignationID')[0].value;
            const userId = getXml(loginResponse.data.ExecuteMobiDataResult[0]).getElementsByTagName('UserID')[0].value;
            const teamId = getXml(loginResponse.data.ExecuteMobiDataResult[0]).getElementsByTagName('TeamID')[0].value;
            cookies.save('userName', userFullName);
            cookies.save('teamId', teamId);
            if (!cookies.load('customerId')) {
              cookies.save('customerId', 'WZK-TRH');
            }

            const afterLoginResponse = await post({
              method: 'InitializeMobi',
              params: {
                paramList: JSON.stringify({
                  designationID: designationId,
                  option: 'GetDataOnPageLoadOrLogin',
                  teamID: teamId,
                  projectName: 'FusionMobi',
                  userID: userId,
                }),
              },
            });

            // console.log(afterLoginResponse.data.ExecuteMobiDataResult);
            // console.log(JSON.parse(afterLoginResponse.data.ExecuteMobiDataResult[2]));
            cookies.save('locationData', JSON.parse(afterLoginResponse.data.ExecuteMobiDataResult[8]));
            cookies.save('companyData', JSON.parse(afterLoginResponse.data.ExecuteMobiDataResult[19]));
            cookies.save('formats', JSON.parse(afterLoginResponse.data.ExecuteMobiDataResult[2]));
            cookies.save('feedbackIds', JSON.parse(afterLoginResponse.data.ExecuteMobiDataResult[20]));

            history.push('/admin/sale-by-item');
          }}
        >
          {(props) => (
            <Form>
              <Box>
                <FormInput validators={validators} field='username' label={'Username'} type={'text'} />
              </Box>
              <Box mt={'20px'}>
                <FormInput validators={validators} field='password' label={'Password'} type={'password'} />
              </Box>
              <Box mt={'20px'}>
                <FormInput validators={validators} field='remember' label={'Stay signed in'} type={'checkbox'} />
              </Box>
              <Button
                w={'100%'}
                mt={4}
                colorScheme='blue'
                isLoading={props.isSubmitting}
                type='submit'
              >
                Login
              </Button>
              <Box fontSize={12} color={'red.400'} mt={2}>{loginError}</Box>
            </Form>
          )}
        </Formik>
        {isCustomerIdInputVisible && (
          <InputGroup mt={4}>
            <InputLeftAddon children='RCID' />
            <Input type='text' value={customerId} onChange={e => setCustomerId(e.target.value)} />
            {initialCustomerId !== customerId ? (
              <InputRightElement width='4.5rem'>
                <Button h='1.75rem' size='sm' onClick={() => changeCustomerId()}>
                  Save
                </Button>
              </InputRightElement>
            ) : null}
          </InputGroup>
        )}
      </Box>
    </Flex>
  );
}

Login.propTypes = {};

export default Login;
