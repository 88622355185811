import React from 'react';
import PropTypes from 'prop-types';
import { Box, Flex } from '@chakra-ui/react';
import PercentageBar from '../../components/PercentageBar/PercentageBar';

function RatingBar(props) {
  const { title, rating, percentage } = props;
  return (
    <Flex alignItems={'center'}>
      <Box w={100} mr={2} fontSize={12}>{title}</Box>
      <Flex flex={1} alignItems={'center'}>
        <PercentageBar percentage={percentage} />
        <Box fontSize={12} ml={4} w={35}>★ {rating}</Box>
      </Flex>
    </Flex>
  );
}

RatingBar.propTypes = {
  title: PropTypes.string,
  rating: PropTypes.number,
  percentage: PropTypes.number,
};

export default RatingBar;
