import React, { useEffect, useState } from 'react';
import { Box, Flex, Icon } from '@chakra-ui/react';
import { Link } from 'react-router-dom';
import { FiChevronLeft } from 'react-icons/all';
import ReviewItem from './ReviewItem';
import cookies from 'react-cookies';
import useGetTableData from '../../hooks/useGetTableData';
import Loader from '../../components/Loader/Loader';
import queryString from 'querystring';

function FeedbackAnalysisReviews(props) {
  const REPORT_ID = 70;

  const [scaleData, setScaleData] = useState(null);
  const [reviews, setReviews] = useState([]);

  const qs = queryString.parse(window.location.search.substring(1));

  const { tableData, isLoading } = useGetTableData({
    reportId: REPORT_ID,
    duration: 8,
    location: qs.location,
    feedback: qs.feedback,
    dateRange: qs.duration,
    shouldGetRawResponse: true,
  });

  useEffect(() => {
    if (tableData.ExecuteMobiDataResult) {
      const selectedLocation = cookies.load('lastSelectedLocation') ? cookies.load('lastSelectedLocation').label : 'All Location';
      const scaleData_ = JSON.parse(tableData.ExecuteMobiDataResult[0]).filter(locationData => {
        return locationData.LocationName === selectedLocation;
      })[0];
      setScaleData(scaleData_);
      setReviews(JSON.parse(tableData.ExecuteMobiDataResult[1]));
    }
  }, [tableData]);

  return (
    <Box>
      <Loader isVisible={isLoading} />

      {scaleData && (
        <>
          <Box pos={'fixed'} top={5} left={10} fontWeight={'bold'} zIndex={2}>{qs.type} Rated Orders
            ({scaleData[`${qs.type}RatedOrderCount`]})</Box>
          <Flex as={Link} bg={'gray.200'} px={2} py={1} borderRadius={4} alignItems={'center'} pos={'fixed'} top={4}
                right={4}
                fontWeight={'bold'} zIndex={2} to={'/admin/feedback-analysis'}>
            Back <Icon boxSize={5} as={FiChevronLeft} />
          </Flex>

          {reviews.map((review, index) => {
            // eslint-disable-next-line no-eval
            if (eval(`${review.AverageScale}${scaleData[`${qs.type}RatedFilter`].charAt(0) === '=' ? '=' : ''}${scaleData[`${qs.type}RatedFilter`]}`)) {
              return <ReviewItem qs={qs} review={review} key={index} index={index} />;
            }
            return null;
          })}
        </>
      )}

    </Box>
  );
}

FeedbackAnalysisReviews.propTypes = {};

export default FeedbackAnalysisReviews;
