import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import cookies from 'react-cookies';
import { Flex, Icon } from '@chakra-ui/react';
import SelectionModal from '../SelectionModal/SelectionModal';
import { BiChevronDown } from 'react-icons/all';

function FeedbackControl(props) {
  const { onSelect } = props;
  const [feedbacks, setFeedbacks] = useState([]);
  const [selectedFeedback, setSelectedFeedback] = useState(null);
  const [isModalOpen, setIsModalOpen] = useState(false);
  useEffect(() => {
    if (cookies.load('feedbackIds')) {
      const feedbacks_ = [];
      cookies.load('feedbackIds').map(feedbackId => {
        feedbacks_.push({
          value: feedbackId.FeedbackID,
          label: feedbackId.FeedbackName,
        });
        return feedbackId;
      });
      setFeedbacks(feedbacks_);

      const lastSelectedFeedback = cookies.load('lastSelectedFeedback') || feedbacks_[0];
      setSelectedFeedback(lastSelectedFeedback);
    }
  }, []);
  return (
    <>
      <Flex alignItems={'center'} cursor={'pointer'} borderRadius={4} p={2} fontSize={14}
            whiteSpace={'nowrap'} h={10} fontWeight={'bold'}
            onClick={() => {
              setIsModalOpen(true);
            }}>
        {selectedFeedback && selectedFeedback.label}<Icon as={BiChevronDown} ml={1} />
      </Flex>
      <SelectionModal options={feedbacks} headerBg={'#410E90'} bg={'#F4F4F4'} title={'Select Feedback'}
                      selectionColor={'#410E90'}
                      selectedOption={selectedFeedback && selectedFeedback.value}
                      onOptionSelect={(option) => {
                        onSelect(option.value);
                        setIsModalOpen(false);
                        setSelectedFeedback(option);
                        cookies.save('lastSelectedFeedback', option);
                      }}
                      isOpen={isModalOpen}
                      onModalClose={() => {
                        setIsModalOpen(false);
                      }} />
    </>
  );
}

FeedbackControl.propTypes = {
  onSelect: PropTypes.func,
};

export default FeedbackControl;
