import React from 'react';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';
import { Box, ChakraProvider, theme } from '@chakra-ui/react';
import Admin from './components/Admin/Admin';
import Login from './containers/Login/Login';
import Dashboard from './containers/Dashboard/Dashboard';
import Feedback from './containers/Feedback/Feedback';

function App() {
  return (
    <ChakraProvider theme={theme}>
      <Router>
        <div>
          <Box color={'#616161'}>
            <Switch>
              <Route path='/login'>
                <Login />
              </Route>
              <Route path='/dashboard'>
                <Dashboard />
              </Route>
              <Route path='/feedback'>
                <Feedback />
              </Route>
              <Route path='/admin'>
                <Admin />
              </Route>
              <Route path='/'>
                <Login />
              </Route>
            </Switch>
          </Box>
        </div>
      </Router>
    </ChakraProvider>
  );
}

export default App;
