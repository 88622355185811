import React, { useState } from 'react';
import { Box, Flex, Link } from '@chakra-ui/react';
import { MdKeyboardArrowDown } from 'react-icons/md';
import PropType from 'prop-types';
import { NavLink } from 'react-router-dom';

function MainItem(props) {
  const links = props.menuItem.subMenu.map(subMenuItem => subMenuItem.link);
  const [isMenuExpanded, setIsMenuExpanded] = useState(links.indexOf(window.location.pathname) > -1);
  const subMenu = props.menuItem.subMenu;
  const handleMainItemClick = () => {
    if (subMenu.length) {
      setIsMenuExpanded(!isMenuExpanded);
    }
  };
  return (
    <Box>
      <Box py={'12px'} pr={'28px'} pl={'32px'} cursor={'pointer'} _hover={{ backgroundColor: '#202C3F' }}
           onClick={handleMainItemClick}>
        <Flex alignItems={'center'} justify={'space-between'}>
          <Flex>
            {props.menuItem.icon}
            <Box fontSize={'13px'} color={'#eeeeee'} pl={'16px'}>{props.menuItem.name}</Box>
          </Flex>
          <MdKeyboardArrowDown color={'#888F99'} size={'20px'}
                               style={{ transform: `rotate(${isMenuExpanded ? 180 : 0}deg)` }}
          />
        </Flex>
      </Box>
      <Box transition={'all 0.3s'} height={isMenuExpanded ? `${35 * subMenu.length}px` : 0} overflow={'hidden'}>
        {subMenu.map((subMenuItem) => {
          return (
            <Link as={NavLink} to={subMenuItem.link} key={subMenuItem.name} d={'block'} exact
                  onClick={props.menuClicked}
                  isActive={(match, location) => {
                    return !(!match || location.pathname === '/');
                  }}
                  _hover={{ backgroundColor: '#202C3F', color: '#eeeeee' }} cursor={'pointer'} color={'#eeeeeeb3'}
                  _focus={{ outline: 'none' }} activeStyle={{ backgroundColor: '#202C3F', color: 'tomato' }}
                  fontSize={'13px'} pl={'70px'} py={'8px'} whiteSpace={'nowrap'} textOverflow={'ellipsis'}
                  overflow={'hidden'} title={subMenuItem.name}>
              {subMenuItem.name}
            </Link>
          );
        })}
      </Box>

    </Box>
  );
}

MainItem.propTypes = {
  menuItem: PropType.object,
  menuClicked: PropType.func,
};

export default MainItem;
