import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Flex, Icon } from '@chakra-ui/react';
import { AiOutlineExpandAlt, AiOutlineShrink } from 'react-icons/all';

function DetailSwitch(props) {
  const { bg, onChange } = props;
  const [isDetailed, setIsDetailed] = useState(false);
  return (
    <Flex alignItems={'center'} cursor={'pointer'} color={'white'} bg={isDetailed ? 'gray.500' : bg} borderRadius={4}
          p={2} fontSize={14}
          whiteSpace={'nowrap'} h={10}
          onClick={() => {
            setIsDetailed(!isDetailed);
            onChange(!isDetailed);
          }}>
      <Icon as={isDetailed ? AiOutlineExpandAlt : AiOutlineShrink} color={'white'}
            mr={1} /> {isDetailed ? 'Summary' : 'Details'}
    </Flex>
  );
}

DetailSwitch.propTypes = {
  onChange: PropTypes.func,
  bg: PropTypes.string,
};

export default DetailSwitch;
