import React from 'react';
import PropTypes from 'prop-types';
import { Box, Flex, HStack, Icon } from '@chakra-ui/react';
import {
  AiFillStar,
  AiOutlineStar,
  ImAngry,
  ImAngry2,
  ImHappy,
  ImHappy2,
  ImNeutral,
  ImNeutral2,
  ImSad,
  ImSad2,
  ImSmile,
  ImSmile2,
} from 'react-icons/all';

const getSmileByRating = (rating, isFilled) => {
  switch (rating) {
    default:
    case 1:
      return isFilled ? ImAngry2 : ImAngry;
    case 2:
      return isFilled ? ImSad2 : ImSad;
    case 3:
      return isFilled ? ImNeutral2 : ImNeutral;
    case 4:
      return isFilled ? ImSmile2 : ImSmile;
    case 5:
      return isFilled ? ImHappy2 : ImHappy;
  }
};

function Scale(props) {
  const { label, selectedRating, onRatingSelected, size, type } = props;
  const getStars = ({ rating }) => {
    return (size === 5 ? [1, 2, 3, 4, 5] : [1, 2, 3]).map(star => {
      if (type === 'Star') {
        if (rating >= star) {
          return (
            <Box key={star} onClick={() => onRatingSelected(star)}>
              <Icon boxSize={6} as={AiFillStar} color={'orange'} />
            </Box>
          );
        }
        return (
          <Box key={star} onClick={() => onRatingSelected(star)}>
            <Icon boxSize={6} as={AiOutlineStar} />
          </Box>
        );
      } else {
        if (rating >= star) {
          return (
            <Box key={star} onClick={() => onRatingSelected(star)}>
              <Icon boxSize={6} as={getSmileByRating(star, true)} color={'orange'} />
            </Box>
          );
        }
        return (
          <Box key={star} onClick={() => onRatingSelected(star)}>
            <Icon boxSize={6} as={getSmileByRating(star, false)} />
          </Box>
        );
      }
    });
  };

  return (
    <Flex justifyContent={'space-between'} alignItems={'center'}>
      <Box>{label}</Box>
      <HStack spacing={1}>
        {getStars({ rating: selectedRating })}
      </HStack>
    </Flex>
  );
}

Scale.propTypes = {
  label: PropTypes.string,
  selectedRating: PropTypes.number,
  onRatingSelected: PropTypes.func,
  size: PropTypes.number,
  type: PropTypes.string,
};

export default Scale;
