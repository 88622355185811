import React, { useState } from 'react';
import { Box } from '@chakra-ui/react';
import cookies from 'react-cookies';
import Loader from '../../components/Loader/Loader';
import DataTable from '../../components/DataTable/DataTable';
import useGetTableData from '../../hooks/useGetTableData';
import Controls from '../../components/Controls/Controls';

function StockStatement() {

  const REPORT_ID = 39;

  const [location, setLocation] = useState(cookies.load('lastSelectedLocation') ? cookies.load('lastSelectedLocation').value : 0);
  const [isDetailed, setIsDetailed] = useState(false);

  const { tableData, isLoading } = useGetTableData({ reportId: REPORT_ID, location: location, duration: 0 });

  return (
    <Box p={'20px'}>
      <Loader isVisible={isLoading} />

      <Controls
        onLocationSelect={setLocation}
        onIsDetailedSwitch={setIsDetailed}
      />
      <DataTable tableData={tableData}
                 reportId={REPORT_ID}
                 isDetailed={isDetailed}
      />
    </Box>
  );
}

StockStatement.propTypes = {};

export default StockStatement;
