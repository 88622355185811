import axios from 'axios';
import cookies from 'react-cookies';
import swal from 'sweetalert';

const HOST = `${process.env.REACT_APP_API_HOST}/api/execute-mobi-data`;

export const post = ({ method, params }) => {
  return axios.post(`${HOST}`, {
    dllName: 'RanceLab.Mobi.ver.1.0.dll',
    className: 'Mobi.MobiHelper',
    methodName: method,
    parameterList: JSON.stringify(params),
    xmlAvailable: false,
    rcId: cookies.load('customerId') || 'WZK-TRH',
  }, {
    headers: {
      Authorization: 'Bearer eyJhbGciOiJIUzI1NiJ9.UmFuY2VMYWI.5ENAqVQaq_uU74z_pX3UBShjxdh8xb4b2OcZwOZOZVk',
    },
  });
};

export const apiCall = async ({ method, url, data, params, headers, skipErrorHandling }) => {
  try {
    const headersToSend = {
      ...headers,
    };

    const response = await axios.request({
      method,
      url,
      data,
      headers: headersToSend,
      params,
    });
    return response.data;
  } catch (e) {
    console.log(e);
    const response = e.response.data;
    if (skipErrorHandling) {
      return;
    }
    switch (e.response.status) {
      case 400:
        swal({
          icon: 'error',
          title: response.message,
        });
        break;
      case 403:
        window.location.href = '/login';
        break;
      case 422:
        swal({
          icon: 'error',
          title: response.message,
          text: Object.values(response.errors)[0][0],
        });
        break;
      default:
        swal({
          icon: 'error',
          title: 'Unknown error occurred',
        });
    }
  }
};
