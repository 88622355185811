import React, { useEffect, useState } from 'react';
import { Box } from '@chakra-ui/react';
import { Bar } from 'react-chartjs-2';
import cookies from 'react-cookies';
import Loader from '../../components/Loader/Loader';
import DataTable from '../../components/DataTable/DataTable';
import useGetTableData from '../../hooks/useGetTableData';
import Controls from '../../components/Controls/Controls';

const options = {
  scales: {
    yAxes: [
      {
        ticks: {
          beginAtZero: true,
        },
      },
    ],
  },
};

function SaleByWeekday() {

  const REPORT_ID = 32;

  const [location, setLocation] = useState(cookies.load('lastSelectedLocation') ? cookies.load('lastSelectedLocation').value : 0);

  const [chartData, setChartData] = useState({});
  const [isChartVisible, setIsChartVisible] = useState(true);

  const { tableData, isLoading } = useGetTableData({ reportId: REPORT_ID, location: location, duration: 0 });

  useEffect(() => {
    const labels = [];
    const thisMonth = [];
    const lastMonth = [];
    const thisWeek = [];
    const lastWeek = [];
    tableData.map(row => {
      labels.push(row.WeekDayName);
      thisMonth.push(row.ThisMonth);
      lastMonth.push(row.LastMonth);
      thisWeek.push(row.ThisWeek);
      lastWeek.push(row.LastWeek);
      return row;
    });

    setChartData({
      labels,
      datasets: [
        {
          label: 'this month',
          data: thisMonth,
          backgroundColor: '#E53E3E',
        },
        {
          label: 'last month',
          data: lastMonth,
          backgroundColor: '#DD6B20',
        },
        {
          label: 'this week',
          data: thisWeek,
          backgroundColor: '#38A169',
        },
        {
          label: 'last week',
          data: lastWeek,
          backgroundColor: '#3182CE',
        },
      ],
    });
  }, [tableData]);

  return (
    <Box p={'20px'}>
      <Loader isVisible={isLoading} />

      <Controls
        onLocationSelect={setLocation}
        onChartVisibilitySwitch={setIsChartVisible}
        isChartDefault={true}
      />

      {isChartVisible ? (
        <Bar data={chartData} options={options} />
      ) : (
        <DataTable tableData={tableData}
                   reportId={REPORT_ID}
                   isDetailed={false}
        />
      )}
    </Box>
  );
}

SaleByWeekday.propTypes = {};

export default SaleByWeekday;
