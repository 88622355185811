import React, { useState } from 'react';
import { Box } from '@chakra-ui/react';
import cookies from 'react-cookies';
import Loader from '../../components/Loader/Loader';
import DataTable from '../../components/DataTable/DataTable';
import useGetTableData from '../../hooks/useGetTableData';
import Controls from '../../components/Controls/Controls';
import moment from 'moment';
import { useHistory } from 'react-router-dom';

function SaleRegister() {

  const REPORT_ID = 37;

  const [location, setLocation] = useState(cookies.load('lastSelectedLocation') ? cookies.load('lastSelectedLocation').value : 0);
  const [duration, setDuration] = useState(cookies.load('lastSelectedInterval') ? cookies.load('lastSelectedInterval').value : 0);
  const [isDetailed, setIsDetailed] = useState(false);
  const { tableData, isLoading } = useGetTableData({ reportId: REPORT_ID, location: location, duration });
  const history = useHistory();

  const handleDateClick = (value) => {
    const timestamp = parseInt(value.substr(6, 13));
    const formattedDate = moment.utc(timestamp).add(5, 'hours').add(30, 'minutes').format('MM/DD/YYYY');
    history.push(`/admin/sale-by-voucher?date=${formattedDate}`);
  };

  return (
    <Box p={'20px'}>
      <Loader isVisible={isLoading} />

      <Controls
        onLocationSelect={setLocation}
        onIntervalSelect={setDuration}
        intervalValuesToHide={[0, 1]}
        onIsDetailedSwitch={setIsDetailed}
      />
      <DataTable tableData={tableData}
                 reportId={REPORT_ID}
                 isDetailed={isDetailed}
                 onColumnClick={{
                   VchDate: handleDateClick,
                 }}
      />
    </Box>
  );
}

SaleRegister.propTypes = {};

export default SaleRegister;
