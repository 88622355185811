import React, { useEffect, useState } from 'react';
import { Box } from '@chakra-ui/react';
import cookies from 'react-cookies';
import Loader from '../../components/Loader/Loader';
import DataTable from '../../components/DataTable/DataTable';
import useGetTableData from '../../hooks/useGetTableData';
import Controls from '../../components/Controls/Controls';
import * as queryString from 'querystring';

function SaleByVoucher() {

  const REPORT_ID = 45;
  const [location, setLocation] = useState(cookies.load('lastSelectedLocation') ? cookies.load('lastSelectedLocation').value : 0);
  const [duration, setDuration] = useState(cookies.load('lastSelectedInterval') ? cookies.load('lastSelectedInterval').value : 0);
  const [qs, setQs] = useState({});

  const { tableData, isLoading } = useGetTableData({
    reportId: REPORT_ID,
    location,
    duration: qs.date ? 8 : duration,
    dateRange: qs.date ? `${qs.date} 0:00|${qs.date} 0:00` : undefined,
    clickedDate: qs.date ? `${qs.date} 0:00` : undefined,
  });

  useEffect(() => {
    setQs(queryString.parse(window.location.search.substring(1)));
  }, []);

  return (
    <Box p={'20px'}>
      <Loader isVisible={isLoading} />

      <Controls
        onLocationSelect={setLocation}
        onIntervalSelect={qs.date ? null : setDuration}
        intervalValuesToHide={[2, 3, 9]}
      />

      <DataTable
        tableData={tableData}
        reportId={REPORT_ID}
        isDetailed={false}
      />
    </Box>
  );
}

SaleByVoucher.propTypes = {};

export default SaleByVoucher;
