import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import cookies from 'react-cookies';
import { Flex, Icon } from '@chakra-ui/react';
import { HiOfficeBuilding } from 'react-icons/all';
import SelectionModal from '../SelectionModal/SelectionModal';

function CompanyControl(props) {
  const { onSelect } = props;
  const [companies, setCompanies] = useState([]);
  const [selectedCompany, setSelectedCompany] = useState(null);
  const [isModalOpen, setIsModalOpen] = useState(false);
  useEffect(() => {
    if (cookies.load('companyData')) {
      const companies_ = [];
      cookies.load('companyData').map(companyData => {
        companies_.push({
          value: companyData.ID,
          label: companyData.Company,
        });
        return companyData;
      });
      setCompanies(companies_);

      const lastSelectedCompany = cookies.load('lastSelectedCompany') || companies_[0];
      setSelectedCompany(lastSelectedCompany);
    }
  }, []);
  return (
    <>
      <Flex alignItems={'center'} cursor={'pointer'} color={'white'} bg={'#410E90'} borderRadius={4} p={2} fontSize={14}
            whiteSpace={'nowrap'} h={10}
            onClick={() => {
              setIsModalOpen(true);
            }}>
        <Icon as={HiOfficeBuilding} color={'white'} mr={1} /> {selectedCompany && selectedCompany.label}
      </Flex>
      <SelectionModal options={companies} headerBg={'#410E90'} bg={'#F4F4F4'} title={'Select Company'}
                      searchPlaceholder={'Search Company'} selectionColor={'#410E90'}
                      selectedOption={selectedCompany && selectedCompany.value}
                      onOptionSelect={(option) => {
                        onSelect(option.value);
                        setIsModalOpen(false);
                        setSelectedCompany(option);
                        cookies.save('lastSelectedCompany', option);
                      }}
                      isOpen={isModalOpen}
                      isSearchable
                      onModalClose={() => {
                        setIsModalOpen(false);
                      }} />
    </>
  );
}

CompanyControl.propTypes = {
  onSelect: PropTypes.func,
};

export default CompanyControl;
