import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Box, Flex, Icon } from '@chakra-ui/react';
import { BiCaretDownCircle, BiCaretUpCircle } from 'react-icons/all';
import LocationControl from '../LocationControl/LocationControl';
import IntervalControl from '../IntervalControl/IntervalControl';
import OptionControl from '../OptionControl/OptionControl';
import CompanyControl from '../CompanyControl/CompanyControl';
import DetailSwitch from '../DetailSwitch/DetailSwitch';
import ChartSwitch from '../ChartSwitch/ChartSwitch';
import { useResizeDetector } from 'react-resize-detector';
import FeedbackControl from '../FeedbackControl/FeedbackControl';
import LocationWithRatingsControl from '../LocationWithRatingsControl/LocationWithRatingsControl';

function Controls(props) {
  const {
    onLocationSelect,
    onLocationWithRatingsSelect,
    locationRatings,
    onFeedbackSelect,
    onIntervalSelect,
    onOptionSelect,
    options,
    intervalValuesToHide,
    onIsDetailedSwitch,
    onChartVisibilitySwitch,
    onCompanySelect,
    showDateRange,
    isChartDefault,
  } = props;
  const { height, ref } = useResizeDetector();
  const [isExpanded, setIsExpanded] = useState(false);
  return (
    <Box h={isExpanded ? `${height}px` : 12} overflow={'hidden'} transition={'height 0.2s'}>
      <Flex alignItems={'flex-start'} ref={ref}>
        <Flex flex={1} flexWrap={'wrap'}>
          {onLocationSelect && (
            <Box mr={2} mb={2}>
              <LocationControl onSelect={(location) => {
                onLocationSelect(location);
              }} />
            </Box>
          )}
          {onLocationWithRatingsSelect && (
            <Box mr={2} mb={2}>
              <LocationWithRatingsControl locationRatings={locationRatings} onSelect={(location) => {
                onLocationWithRatingsSelect(location);
              }} />
            </Box>
          )}
          {onIntervalSelect && (
            <Box pos={'fixed'} top={3} right={4} zIndex={2}>
              <IntervalControl showDateRange={showDateRange} onSelect={(duration) => {
                onIntervalSelect(duration);
              }} valuesToHide={intervalValuesToHide} />
            </Box>
          )}
          {onOptionSelect && (
            <Box mr={2} mb={2}>
              <OptionControl onSelect={(option) => {
                onOptionSelect(option);
              }} options={options} />
            </Box>
          )}
          {onIsDetailedSwitch && (
            <Box mr={2} mb={2}>
              <DetailSwitch bg={'#A80E73'} onChange={isDetailed => onIsDetailedSwitch(isDetailed)} />
            </Box>
          )}
          {onChartVisibilitySwitch && (
            <Box mr={2} mb={2}>
              <ChartSwitch isChartDefault={isChartDefault} bg={'#A80E73'}
                           onChange={isCharted => onChartVisibilitySwitch(isCharted)} />
            </Box>
          )}
          {onCompanySelect && (
            <Box mr={2} mb={2}>
              <CompanyControl onSelect={(company) => {
                onCompanySelect(company);
              }} />
            </Box>
          )}
          {onFeedbackSelect && (
            <Box pos={'fixed'} top={3} left={8} zIndex={2}>
              <FeedbackControl onSelect={(feedback) => {
                onFeedbackSelect(feedback);
              }} />
            </Box>
          )}
        </Flex>
        {height > 48 && (
          <Flex alignItems={'center'} fontSize={14} cursor={'pointer'} h={10} px={2} onClick={() => {
            setIsExpanded(!isExpanded);
          }}>
            {isExpanded ? 'Less' : 'More'} <Icon ml={1} as={!isExpanded ? BiCaretDownCircle : BiCaretUpCircle} />
          </Flex>
        )}
      </Flex>
    </Box>
  );
}

Controls.propTypes = {
  onLocationSelect: PropTypes.func,
  onLocationWithRatingsSelect: PropTypes.func,
  locationRatings: PropTypes.array,
  onFeedbackSelect: PropTypes.func,
  onIntervalSelect: PropTypes.func,
  intervalValuesToHide: PropTypes.array,
  onOptionSelect: PropTypes.func,
  options: PropTypes.array,
  onIsDetailedSwitch: PropTypes.func,
  onChartVisibilitySwitch: PropTypes.func,
  onCompanySelect: PropTypes.func,
  showDateRange: PropTypes.bool,
  isChartDefault: PropTypes.bool,
};

Controls.defaultValues = {
  onLocationWithRatingsSelect: null,
  onLocationSelect: null,
  onFeedbackSelect: null,
  onIntervalSelect: null,
  intervalValuesToHide: [],
  onOptionSelect: null,
  options: [],
  onIsDetailedSwitch: null,
  onChartVisibilitySwitch: null,
  onCompanySelect: null,
  showDateRange: false,
  isChartDefault: false,
  locationRatings: [],
};

export default Controls;
