import React from 'react';
import PropTypes from 'prop-types';
import { Box, Icon, IconButton, Table, Tbody, Td, Th, Thead, Tr } from '@chakra-ui/react';
import { CgArrowLeft } from 'react-icons/all';

function DetailsTable(props) {
  const { backClicked, data } = props;
  return (
    <Box>
      <IconButton aria-label='Search database' icon={<Icon as={CgArrowLeft} />} onClick={backClicked} />
      <Table variant='simple'>
        <Thead>
          <Tr>
            <Th>RCID</Th>
            <Th>Company</Th>
            <Th>Total Data</Th>
            <Th>Not Sent</Th>
            <Th>Sent</Th>
            <Th>Acknowledged</Th>
            <Th>Cancelled</Th>
            <Th>Duplicate</Th>
            <Th>Status</Th>
          </Tr>
        </Thead>
        <Tbody>
          {data.map(row => {
            return (
              <Tr key={row.branch}>
                <Td>{row.headOffice}</Td>
                <Td>{row.companyName}</Td>
                <Td>{row.totalData}</Td>
                <Td>{row.notSent}</Td>
                <Td>{row.sent}</Td>
                <Td>{row.acknowledged}</Td>
                <Td>{row.cancelled}</Td>
                <Td>{row.duplicate}</Td>
                <Td color={'white'} backgroundColor={row.connected ? 'green' : 'red'}>{row.connected}</Td>
              </Tr>
            );
          })}
        </Tbody>
      </Table>
    </Box>
  );
}

DetailsTable.propTypes = {
  data: PropTypes.array,
  backClicked: PropTypes.func,
};

export default DetailsTable;
