import React, { useState } from 'react';
import { Box } from '@chakra-ui/react';
import cookies from 'react-cookies';
import Loader from '../../components/Loader/Loader';
import DataTable from '../../components/DataTable/DataTable';
import useGetTableData from '../../hooks/useGetTableData';
import Controls from '../../components/Controls/Controls';

function DayEndReport() {

  const REPORT_ID = 43;
  const [location, setLocation] = useState(cookies.load('lastSelectedLocation') ? cookies.load('lastSelectedLocation').value : 0);
  const [duration, setDuration] = useState(cookies.load('lastSelectedInterval') ? cookies.load('lastSelectedInterval').value : 0);
  const options = [
    { value: 0, label: 'operator' },
    { value: 1, label: 'station' },
  ];
  const [option, setOption] = useState(options[0].value);

  const { tableData, isLoading } = useGetTableData({ reportId: REPORT_ID, duration, location, option });

  return (
    <Box p={'20px'}>
      <Loader isVisible={isLoading} />

      <Controls onLocationSelect={setLocation}
                onIntervalSelect={setDuration}
                intervalValuesToHide={[9]}
                onOptionSelect={setOption}
                options={options}
      />

      <DataTable tableData={tableData}
                 reportId={REPORT_ID}
                 isDetailed={false}
      />
    </Box>
  );
}

DayEndReport.propTypes = {};

export default DayEndReport;
