import React, { useEffect, useState } from 'react';
import { Box, Divider, Flex, HStack, Icon, Table, Tbody, Td, Th, Thead, Tr } from '@chakra-ui/react';
import { Link } from 'react-router-dom';
import { FiChevronLeft } from 'react-icons/all';
import queryString from 'querystring';
import useGetTableData from '../../hooks/useGetTableData';
import Loader from '../../components/Loader/Loader';

function FeedbackAnalysisReview(props) {
  const REPORT_ID = 70;

  const qs = queryString.parse(window.location.search.substring(1));

  const [review, setReview] = useState({});
  const [items, setItems] = useState([]);

  const { tableData, isLoading } = useGetTableData({
    reportId: REPORT_ID,
    duration: 8,
    location: qs.location,
    feedback: qs.feedback,
    dateRange: qs.duration,
    shouldGetRawResponse: true,
  });

  useEffect(() => {
    if (tableData.ExecuteMobiDataResult) {
      const review_ = JSON.parse(tableData.ExecuteMobiDataResult[1])[qs.index];
      setReview(review_);
      const items_ = JSON.parse(tableData.ExecuteMobiDataResult[2]).filter(item => {
        return item.SerialNumber === review_.SerialNumber;
      });
      setItems(items_);
    }
  }, [qs.index, tableData]);

  return (
    <>
      <Loader isVisible={isLoading} />
      <Box pos={'fixed'} top={5} left={10} fontWeight={'bold'} zIndex={2}>{review.CustomerName}</Box>
      <Flex as={Link} bg={'gray.200'} px={2} py={1} borderRadius={4} alignItems={'center'} pos={'fixed'} top={4}
            right={4}
            fontWeight={'bold'} zIndex={2}
            to={`/admin/feedback-analysis/reviews?location=${qs.location}&feedback=${qs.feedback}&duration=${qs.duration}&type=${qs.type}`}>
        Back <Icon boxSize={5} as={FiChevronLeft} />
      </Flex>
      <Box d={'block'} as={Link} p={5} borderBottom={'1px solid'} borderColor={'gray.200'}
           to={'/admin/feedback-analysis/review'}>
        <Flex justifyContent={'space-between'} fontSize={12}>
          <Box>{review.FeedbackDateTime}</Box>
          <Box>User: {review.User}</Box>
        </Flex>
        <Flex justifyContent={'space-between'} mt={4}>
          <Box fontWeight={'bold'}>
            <Box>{review.CustomerName}</Box>
            <Box mt={2}>★ {review.AverageScale}</Box>
          </Box>
          <Box>
            <Box>{review.VchNumber} | <Box as={'span'} fontWeight={'bold'}>₹{review.BillAmount}</Box></Box>
            <Box mt={2}>Table {review.TableName} Pax {review.Pax}</Box>
          </Box>
        </Flex>
        <Box mt={4} fontSize={14}>
          <Box>{review.Question1}</Box>
          <Box>{review.Question2}</Box>
          <Box>{review.Question3}</Box>
        </Box>
        <HStack spacing={2} fontSize={14} mt={4}>
          <Box>{review.Scale1Tags}</Box>
          <Box>{review.Scale2Tags}</Box>
          <Box>{review.Scale3Tags}</Box>
          <Box>{review.Scale4Tags}</Box>
          <Box>{review.Scale5Tags}</Box>
        </HStack>
        <Divider mt={4} />
        <Flex mt={4} fontSize={14}>
          <Box>
            <Box fontWeight={'bold'}>Mobile</Box>
            <Box>{review.Mobile}</Box>
          </Box>
          <Box ml={6}>
            <Box fontWeight={'bold'}>Email</Box>
            <Box>{review.Email}</Box>
          </Box>
        </Flex>
        <Divider mt={4} />
        <Table mt={4} fontSize={14} variant={'unstyled'}>
          <Thead>
            <Tr fontWeight={'bold'}>
              <Th p={0}>Item</Th>
              <Th p={0}>Amt</Th>
              <Th p={0}>Waiter</Th>
            </Tr>
          </Thead>
          <Tbody>
            {items.map((item, index) => {
              return (
                <Tr key={index}>
                  <Td p={'20px 0 0 0'}>{item.ProductName} x {item.Qty}</Td>
                  <Td p={'20px 0 0 0'}>₹{item.Amount}</Td>
                  <Td p={'20px 0 0 0'}>{item.Waiter}</Td>
                </Tr>
              );
            })}
          </Tbody>
        </Table>
      </Box>
    </>
  );
}

FeedbackAnalysisReview.propTypes = {};

export default FeedbackAnalysisReview;
