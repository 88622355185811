import React from 'react';
import { Box, Button, Modal, ModalBody, ModalContent, ModalOverlay } from '@chakra-ui/react';
import PropTypes from 'prop-types';
import { Form, Formik } from 'formik';
import FormInput from '../FormField/FormInput';
import moment from 'moment';

function DateRangeModal(props) {
  const {
    headerBg,
    bg,
    isOpen,
    onModalClose,
    onRangeSelect,
    from,
    to,
  } = props;

  const validators = {
    from: (value) => {
      let error;
      if (!value) {
        error = 'Start date is required';
      }
      return error;
    },
    to: (value) => {
      let error;
      if (!value) {
        error = 'End date is required';
      }
      return error;
    },
  };

  return (
    <Modal isOpen={isOpen} onClose={onModalClose}>
      <ModalOverlay />
      <ModalContent width={'fit-content'} borderRadius={0} minW={290}>
        <ModalBody p={0} bg={bg}>
          <Box bg={headerBg} color={'white'} fontSize={14} py={4} textAlign={'center'} px={4} fontWeight={'bold'}>
            Select Date Range
          </Box>
          <Box h={'60vh'} overflow={'auto'} p={2}
            // onClick={() => onRangeSelect('01/01/2020 00:00|08/10/2021 23:59')}
          >
            <Formik
              initialValues={{ from, to }}
              onSubmit={(values, actions) => {
                onRangeSelect(`${moment(values.from).format('DD/MM/YYYY')} 00:00|${moment(values.to).format('DD/MM/YYYY')} 23:59`);
              }}
            >
              {(props) => (
                <Form>
                  <FormInput validators={validators} field='from' label={'Start date'} type={'date'} />
                  <Box py={4} textAlign={'center'}>to</Box>
                  <FormInput validators={validators} field='to' label={'End date'} type={'date'} />
                  <Button type={'submit'} colorScheme={'purple'} w={'100%'} mt={8}>Set</Button>
                </Form>
              )}
            </Formik>
          </Box>
        </ModalBody>
      </ModalContent>
    </Modal>
  );
}

DateRangeModal.propTypes = {
  bg: PropTypes.string,
  headerBg: PropTypes.string,
  isOpen: PropTypes.bool,
  onModalClose: PropTypes.func,
  onRangeSelect: PropTypes.func,
  from: PropTypes.string,
  to: PropTypes.string,
};

DateRangeModal.defaultProps = {
  from: '',
  to: '',
};

export default DateRangeModal;
