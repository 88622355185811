import React, { useEffect, useState } from 'react';
import { Box } from '@chakra-ui/react';
import { Line } from 'react-chartjs-2';
import cookies from 'react-cookies';
import Loader from '../../components/Loader/Loader';
import DataTable from '../../components/DataTable/DataTable';
import useGetTableData from '../../hooks/useGetTableData';
import Controls from '../../components/Controls/Controls';

const options = {
  scales: {
    yAxes: [
      {
        ticks: {
          beginAtZero: true,
        },
      },
    ],
  },
};


function SaleByHour() {

  const REPORT_ID = 44;
  const [location, setLocation] = useState(cookies.load('lastSelectedLocation') ? cookies.load('lastSelectedLocation').value : 0);

  const [chartData, setChartData] = useState({});
  const [isChartVisible, setIsChartVisible] = useState(true);

  const { tableData, isLoading } = useGetTableData({ reportId: REPORT_ID, location, duration: 0 });

  useEffect(() => {
    const labels = [];
    const thisMonth = [];
    const lastMonth = [];
    tableData.map(row => {
      labels.push(row.Hour);
      thisMonth.push(row.ThisMonth);
      lastMonth.push(row.LastMonth);
      return row;
    });

    setChartData({
      labels,
      datasets: [
        {
          label: 'this month',
          data: thisMonth,
          fill: false,
          backgroundColor: 'tomato',
          borderColor: 'tomato',
        },
        {
          label: 'last month',
          data: lastMonth,
          fill: false,
          backgroundColor: 'teal',
          borderColor: 'teal',
        },
      ],
    });
  }, [tableData]);

  return (
    <Box p={'20px'}>
      <Loader isVisible={isLoading} />

      <Controls onLocationSelect={setLocation} onChartVisibilitySwitch={setIsChartVisible} isChartDefault={true} />

      {isChartVisible ? (
        <Line data={chartData} options={options} />
      ) : (
        <DataTable tableData={tableData}
                   reportId={REPORT_ID}
                   isDetailed={false}
        />
      )}

    </Box>
  );
}

SaleByHour.propTypes = {};

export default SaleByHour;
