import React, { useState } from 'react';
import { Box } from '@chakra-ui/react';
import cookies from 'react-cookies';
import Loader from '../../components/Loader/Loader';
import DataTable from '../../components/DataTable/DataTable';
import useGetTableData from '../../hooks/useGetTableData';
import Controls from '../../components/Controls/Controls';

function BankAndCashbook() {

  const REPORT_ID = 5;

  const [location, setLocation] = useState(cookies.load('lastSelectedLocation') ? cookies.load('lastSelectedLocation').value : 0);
  const [duration, setDuration] = useState(cookies.load('lastSelectedInterval') ? cookies.load('lastSelectedInterval').value : 0);
  const { tableData, isLoading } = useGetTableData({ reportId: REPORT_ID, duration, location });
  const [isDetailed, setIsDetailed] = useState(false);
  return (
    <Box p={'20px'}>
      <Loader isVisible={isLoading} />
      <Controls onIntervalSelect={setDuration}
                intervalValuesToHide={[9]}
                onIsDetailedSwitch={setIsDetailed}
                onLocationSelect={setLocation}
      />
      <DataTable tableData={tableData}
                 reportId={REPORT_ID}
                 isDetailed={isDetailed}
      />
    </Box>
  );
}

BankAndCashbook.propTypes = {};

export default BankAndCashbook;
