import React, { useEffect, useState } from 'react';
import { Box } from '@chakra-ui/react';
import cookies from 'react-cookies';
import Loader from '../../components/Loader/Loader';
import DataTable from '../../components/DataTable/DataTable';
import useGetTableData from '../../hooks/useGetTableData';
import Controls from '../../components/Controls/Controls';
import { Doughnut } from 'react-chartjs-2';

function SaleByCategory() {

  const REPORT_ID = 3;
  const [location, setLocation] = useState(cookies.load('lastSelectedLocation') ? cookies.load('lastSelectedLocation').value : 0);
  const options = [
    { value: 0, label: 'group' },
    { value: 1, label: 'sub-group' },
    { value: 2, label: 'department' },
    { value: 3, label: 'brand' },
  ];
  const [option, setOption] = useState(options[0].value);
  const [isDetailed, setIsDetailed] = useState(false);
  const { tableData, isLoading } = useGetTableData({ reportId: REPORT_ID, location, option });

  const [chartData, setChartData] = useState({});
  const [isChartVisible, setIsChartVisible] = useState(true);

  useEffect(() => {
    console.log('tabelData', tableData);
    const labels = [];
    const mtd = [];
    const ytd = [];
    const today = [];
    const colors = [];
    tableData.map(item => {
      labels.push(item.ProductGroupName);
      mtd.push(item.MTD);
      ytd.push(item.YTD);
      today.push(item.Today);
      const randomColor = Math.floor(Math.random() * 16777215).toString(16);
      colors.push(`#${randomColor}`);
      return item;
    });
    setChartData({
      labels,
      datasets: [
        {
          label: 'MTD',
          data: mtd,
          backgroundColor: colors,
          borderColor: 'white',
          borderWidth: 1,
        },
        {
          label: 'YTD',
          data: ytd,
          backgroundColor: colors,
          borderColor: 'white',
          borderWidth: 1,
        },
        {
          label: 'Today',
          data: today,
          backgroundColor: colors,
          borderColor: 'white',
          borderWidth: 1,
        },
      ],
    });
  }, [tableData]);

  return (
    <Box p={'20px'}>
      <Loader isVisible={isLoading} />

      <Controls onLocationSelect={setLocation}
                onOptionSelect={setOption}
                options={options}
                onIsDetailedSwitch={setIsDetailed}
                onChartVisibilitySwitch={setIsChartVisible}
                isChartDefault={true}
      />

      {isChartVisible ? (
        <Box>
          <Box fontSize={12} textAlign={'center'}>
            <Box>MTD</Box>
            <Box>YTD</Box>
            <Box>Today</Box>
          </Box>
          <Doughnut data={chartData} />
        </Box>
      ) : (
        <DataTable tableData={tableData}
                   reportId={REPORT_ID}
                   isDetailed={isDetailed}
        />
      )}

    </Box>
  );
}

SaleByCategory.propTypes = {};

export default SaleByCategory;
