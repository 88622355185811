import React from 'react';
import { Box, Stack } from '@chakra-ui/react';
import RatingBar from './RatingBar';
import PropTypes from 'prop-types';

function RatingSummary(props) {
  const { title, percentageMap, countMap } = props;
  return (
    <Box>
      <Box fontWeight={'bold'}>{title}</Box>
      <Stack spacing={4} mt={4}>
        <RatingBar rating={5} percentage={percentageMap['5']} count={countMap['5']} />
        <RatingBar rating={4} percentage={percentageMap['4']} count={countMap['4']} />
        <RatingBar rating={3} percentage={percentageMap['3']} count={countMap['3']} />
        <RatingBar rating={2} percentage={percentageMap['2']} count={countMap['2']} />
        <RatingBar rating={1} percentage={percentageMap['1']} count={countMap['1']} />
      </Stack>
    </Box>
  );
}

RatingSummary.propTypes = {
  title: PropTypes.string,
  percentageMap: PropTypes.object,
  countMap: PropTypes.object,
};

export default RatingSummary;
