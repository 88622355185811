import React, { useState } from 'react';
import { Box } from '@chakra-ui/react';
import cookies from 'react-cookies';
import Loader from '../../components/Loader/Loader';
import DataTable from '../../components/DataTable/DataTable';
import useGetTableData from '../../hooks/useGetTableData';
import Controls from '../../components/Controls/Controls';

function SaleByShift() {

  const REPORT_ID = 46;

  const [location, setLocation] = useState(cookies.load('lastSelectedLocation') ? cookies.load('lastSelectedLocation').value : 0);
  const [duration, setDuration] = useState(cookies.load('lastSelectedInterval') ? cookies.load('lastSelectedInterval').value : 0);

  const { tableData, isLoading } = useGetTableData({ reportId: REPORT_ID, location, duration });

  return (
    <Box p={'20px'}>
      <Loader isVisible={isLoading} />

      <Controls
        onLocationSelect={setLocation}
        onIntervalSelect={setDuration}
        intervalValuesToHide={[9]}
      />

      <DataTable tableData={tableData}
                 reportId={REPORT_ID}
                 isDetailed={false}
                 isTotalRowVisible={false}
      />
    </Box>
  );
}

SaleByShift.propTypes = {};

export default SaleByShift;
