import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Flex, Icon } from '@chakra-ui/react';
import { IoOptions } from 'react-icons/all';
import SelectionModal from '../SelectionModal/SelectionModal';

function OptionControl(props) {
  const options = props.options;
  const { onSelect } = props;
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [selectedOption, setSelectedOption] = useState(options[0]);
  return (
    <>
      <Flex alignItems={'center'} cursor={'pointer'} color={'white'} bg={'#A80E73'} borderRadius={4} p={2} fontSize={14}
            whiteSpace={'nowrap'} h={10}
            onClick={() => {
              setIsModalOpen(true);
            }}>
        <Icon as={IoOptions} color={'white'} mr={1} /> {selectedOption && selectedOption.label}
      </Flex>
      <SelectionModal options={options} headerBg={'#A80E73'} bg={'#F4F4F4'} title={'Select Option'}
                      selectionColor={'#A80E73'}
                      selectedOption={selectedOption && selectedOption.value}
                      onOptionSelect={(option) => {
                        onSelect(option.value);
                        setIsModalOpen(false);
                        setSelectedOption(option);
                      }}
                      isOpen={isModalOpen}
                      isSearchable={false}
                      onModalClose={() => {
                        setIsModalOpen(false);
                      }} />
    </>
  );
}

OptionControl.propTypes = {
  onSelect: PropTypes.func,
  options: PropTypes.array,
};

export default OptionControl;
